import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import { useAddNewExtUserMutation } from '../users/extUsersApiSlice'
import { useModalContext } from '../../hooks/useModalContext'
import { toast } from 'react-toastify'
import { useGetDistributorsQuery } from '../distributors/distributorsApiSlice'
import CustomTextInput from '../../components/CustomTextInput'
import CustomWindowedSelect from '../../components/CustomWindowedSelect'
import { useTheme } from '@mui/material'

const CreateExtUserForm = () => {
  const theme = useTheme()
  const [addNewUser] = useAddNewExtUserMutation()
  const { handleCloseModal } = useModalContext()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [pending, setIsPending] = useState(false)
  const [selectedDistributors, setSelectedDistributors] = useState([])
  const { data: distributors } = useGetDistributorsQuery('distributorsList')

  const distributorOptions =
    distributors &&
    distributors
      .filter((distributor) => distributor.isActive)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((distributor) => {
        return {
          value: distributor,
          label: `${distributor.name} | ${distributor._id}`,
          customLabel: `${distributor.address} ${distributor.city}, ${distributor.state} ${distributor.zip}`,
          key: distributor._id
        }
      })

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsPending(true)

    if (password !== password2) {
      toast.error(<>Passwords do not match</>)
    } else {
      const distributorNames = selectedDistributors.map((distributor) => distributor.value.name)
      const userData = {
        name,
        email,
        password,
        role: 'externaluser',
        distributors: selectedDistributors,
        distributorNames
      }

      try {
        await addNewUser(userData).unwrap()
        toast.success(
          <>
            External user <strong>{name}</strong> has been created.
          </>
        )
      } catch (error) {
        toast.error(
          <>
            External user <strong>{name}</strong> has not been created. {error.data.message}.
          </>
        )
      }
      handleCloseModal('createModal')
    }
  }

  const handleDistributorChange = (selectedOptions) => {
    setSelectedDistributors(selectedOptions)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ px: 2, pb: 1, pt: 2 }}>
        <Grid
          container
          columnSpacing={2}>
          <Grid
            xs={12}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={name}
              onChange={setName}
              label="Name"
              name="name"
            />
          </Grid>
          <Grid
            xs={12}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={email}
              onChange={setEmail}
              label="Email"
              name="email"
              type="email"
            />
          </Grid>
          <Grid
            xs={12}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={password}
              onChange={setPassword}
              label="Password"
              name="password"
              type="password"
            />
          </Grid>
          <Grid
            xs={12}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={password2}
              onChange={setPassword2}
              label="Confirm Password"
              name="confirmPassword"
              type="password"
            />
          </Grid>
          <Grid
            xs
            sx={{ mb: 1 }}>
            <CustomWindowedSelect
              isInModal={true}
              options={distributorOptions}
              onChange={handleDistributorChange}
              titleLabel="Licensee"
              isMulti
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{ px: 2, pb: 2 }}>
        <Stack
          direction="row"
          spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="cancel"
            onClick={() => handleCloseModal('createModal')}
            type="button">
            Cancel
          </Button>
          {!pending && (
            <Button
              variant="contained"
              size="small"
              type="submit"
              color="black"
              disabled={!(name && email && password && password2 && selectedDistributors)}
              sx={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all'
                }
              }}>
              Add User
            </Button>
          )}
          {pending && (
            <Button
              variant="contained"
              size="small"
              type="button"
              disabled>
              <CircularProgress
                sx={{ color: theme.vars.palette.cancel.light, mr: 0.5 }}
                size={18}
              />
              Adding...
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
export default CreateExtUserForm
