import useTitle from '../../hooks/useTitle'
import { useGetEquipmentByUserQuery } from '../equipments/equipmentsApiSlice'
import useAuth from '../../hooks/useAuth'
import BeatLoader from 'react-spinners/BeatLoader'
import ReportsList from '../reports/ReportsList'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Unstable_Grid2'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
dayjs.extend(relativeTime)
dayjs.extend(duration)

const Welcome = () => {
  const { id, name, email, role } = useAuth()
  const { data: equipments, isLoading, isFetching, isError, error } = useGetEquipmentByUserQuery({ userId: id })

  useTitle(`Divvy Dashboard: ${name} | ${email} | ${role}`)

  const formatDaysTileExpiration = (today, expirationDate) => {
    if (!today || !expirationDate) return

    const years = dayjs(expirationDate).diff(dayjs(today), 'year')
    const months = dayjs(expirationDate).diff(dayjs(today), 'month') - years * 12
    const days = dayjs(expirationDate).diff(dayjs(today).add(years, 'year').add(months, 'month'), 'day')
    const diffDays = dayjs(today).diff(dayjs(expirationDate), 'day')

    let text = ''
    let textColor = ''
    let backgroundColor = ''

    if (dayjs(today).isSame(dayjs(expirationDate))) {
      text = 'Expired today'
    } else if (dayjs(today).isBefore(dayjs(expirationDate))) {
      if (years === 0 && months === 0) {
        text = `${days}d left`
        textColor = '#222'
        backgroundColor = '#dce775'
      } else if (years === 0) {
        text = `${days}d ${months}m left`
        textColor = '#fff'
        backgroundColor = '#8bc34a'
      } else {
        text = `${days}d ${months}m ${years}y left`
        textColor = '#fff'
        backgroundColor = '#388e3c'
      }
    } else {
      if (years === 0 && months === 0) {
        text = `Expired ${Math.abs(days)}d`
        textColor = '#222'
        backgroundColor = '#ffeb3b'
      } else if (years === 0) {
        text = `Expired ${Math.abs(days)}d ${Math.abs(months)}m`
        textColor = '#222'
        backgroundColor = '#ff9800'
      } else {
        text = `Expired ${Math.abs(days)}d ${Math.abs(months)}m ${Math.abs(years)}y`
        textColor = '#fff'
        backgroundColor = 'error.main'
      }
    }

    let title
    if (diffDays < 0) {
      title = `${Math.abs(diffDays)} days until expiration`
    } else {
      title = `Expired ${Math.abs(diffDays)} days ago`
    }

    return { backgroundColor, text, textColor, title }
  }

  if (isLoading || isFetching) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Error fetching equipment data: {error.message}</p>
  }

  const columns = [
    {
      field: 'distributorName',
      headerName: 'Distributor',
      maxWidth: 350,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: false,
      hideable: false
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: false
    },
    {
      field: 'installationDate',
      headerName: 'Installation Date',
      maxWidth: 150,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: false,
      renderCell: (params) => (params.row.installationDate ? new Date(params.row.installationDate).toLocaleDateString('en-US') : '')
    },
    {
      field: 'isRegistered',
      headerName: 'Registered?',
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.isRegistered === true ? 'Yes' : 'No'),
      renderCell: (params) => (
        <Box
          sx={{
            color: params.value === 'Yes' ? 'white' : 'error.main',
            backgroundColor: params.value === 'Yes' ? '#388e3c' : '#fff0f3',
            padding: '1px 4px',
            borderRadius: '5px'
          }}>
          {params.value}
        </Box>
      ),
      sortable: true,
      resizable: false
    },
    {
      field: 'unitModel',
      headerName: 'Unit Model',
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: false
    },
    {
      field: 'serialNumber',
      headerName: 'Serial #',
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: false
    },
    {
      field: 'customerType',
      headerName: 'Customer Type',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: false
    },
    {
      field: 'installationType',
      headerName: 'Installation Type',
      maxWidth: 150,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: false
    },
    {
      field: 'compressorHorsepower',
      headerName: 'Compressor HP',
      maxWidth: 125,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: false
    },
    {
      field: 'expirationDate',
      headerName: 'Expiration Date',
      maxWidth: 150,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: false,
      renderCell: (params) => (params.row.expirationDate ? new Date(params.row.expirationDate).toLocaleDateString('en-US') : '')
    },
    {
      field: 'daysUntilExpiration',
      headerName: 'Days until expiration',
      maxWidth: 300,
      minWidth: 100,
      flex: 1,
      sortable: true,
      resizable: false,
      valueGetter: (params) => {
        if (!params.row.expirationDate) {
          return ''
        }
        const expirationDate = new Date(params.row.expirationDate)
        const today = new Date()
        return formatDaysTileExpiration(today, expirationDate)
      },
      renderCell: (params) => {
        const { backgroundColor, text, textColor, title } = params.formattedValue
        return (
          <Tooltip title={title}>
            <Box
              sx={{
                backgroundColor: backgroundColor,
                color: textColor,
                padding: '1px 4px',
                borderRadius: '5px'
              }}>
              {text}
            </Box>
          </Tooltip>
        )
      }
    },
    {
      field: 'isRecycled',
      headerName: 'Recycled?',
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.isRecycled === true ? 'Yes' : 'No'),
      renderCell: (params) => (
        <Box
          sx={{
            color: params.value === 'Yes' ? 'white' : 'error.main',
            backgroundColor: params.value === 'Yes' ? '#388e3c' : '#fff0f3',
            padding: '1px 4px',
            borderRadius: '5px'
          }}>
          {params.value}
        </Box>
      ),
      sortable: true,
      resizable: false
    }
  ]

  const content = (
    <Grid>
      <Typography
        component="h1"
        sx={{ fontSize: '2.5rem', fontWeight: '600' }}>
        Welcome {name}!
      </Typography>
      <Typography
        component="p"
        sx={{ mb: 2 }}>
        Below is a list of the distributors that you are subscribed to.
      </Typography>
      {isLoading || isFetching ? (
        <BeatLoader
          color={'#333'}
          size={20}
          style={{ margin: '8px' }}
        />
      ) : isError ? (
        <Typography>{error.data.message}</Typography>
      ) : (
        <ReportsList
          equipments={equipments}
          columns={columns}
          reportType="External User"
        />
      )}
    </Grid>
  )

  return content
}
export default Welcome
