import MaterialReactTable from 'material-react-table'
import { BsCaretDownFill } from 'react-icons/bs'
import { useTheme } from '@mui/material'

const CustomTable = ({ columns, docs, isError, sorting, handleSetSorting, color, applyIsActiveStyle = true }) => {
  const theme = useTheme()
  return (
    <MaterialReactTable
      columns={columns}
      data={docs}
      defaultColumn={{
        minSize: 50,
        maxSize: 900,
        size: 100
      }}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnFilters={false}
      enableColumnActions={false}
      enablePagination={false}
      initialState={{ showColumnFilters: true, density: 'compact' }}
      icons={{
        ArrowDownwardIcon: (props) => (
          <BsCaretDownFill
            color="white"
            {...props}
          />
        )
      }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={isError ? { color: 'error', children: 'Error loading data' } : undefined}
      muiTablePaperProps={{
        sx: {
          boxShadow: 'none',
          mb: 1
        }
      }}
      muiTableProps={{
        sx: {
          tableLayout: {
            xl: 'fixed',
            xs: 'auto'
          }
        }
      }}
      muiTableHeadCellProps={{
        sx: {
          fontSize: '.825rem',
          backgroundColor: color && color === 'black' ? `${color}.dark` : `${color}.main`,
          color: '#fff',
          border: 0,
          fontWeight: 'normal'
        }
      }}
      muiTableBodyProps={{
        sx: {
          '& tr:nth-of-type(odd)': {
            backgroundColor: '#f5f5f5',
            [theme.getColorSchemeSelector('dark')]: {
              backgroundColor: '#1A2027'
            },
            '&:hover': {
              backgroundColor: theme.vars.palette.action.hover,
              [theme.getColorSchemeSelector('dark')]: {
                backgroundColor: theme.vars.palette.action.hover
              }
            }
          }
        }
      }}
      {...(applyIsActiveStyle && {
        muiTableBodyRowProps: (row) => ({
          style: !row.row.original.isActive ? { backgroundColor: 'lightgray' } : {}
        })
      })}
      muiTableBodyCellProps={(row) => ({
        sx: {
          fontSize: '.8rem',
          py: '.425rem',
          ...(applyIsActiveStyle &&
            !row.row.original.isActive && {
              color: 'rgba(0, 0, 0, 0.5)'
            })
        }
      })}
      onSortingChange={handleSetSorting}
      positionActionsColumn="last"
      rowCount={docs.totalDocs ?? 0}
      sortDescFirst={false}
      state={{
        showAlertBanner: isError,
        sorting
      }}
    />
  )
}

export default CustomTable
