import { useCallback, useState } from 'react'
import ExtUsersHeader from './ExtUsersHeader'
import ExtUsersList from './ExtUsersList'

const ExtUsersPage = () => {
  const [page, setPage] = useState(1)

  const handleSetPage = useCallback(
    (newPage) => {
      setPage(newPage)
    },
    [setPage]
  )

  return (
    <>
      <ExtUsersHeader handleSetPage={handleSetPage} />
      <ExtUsersList
        page={page}
        handleSetPage={handleSetPage}
      />
    </>
  )
}

export default ExtUsersPage
