import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useModalContext } from '../../hooks/useModalContext'
import { useGetDistributorQuery } from '../distributors/distributorsApiSlice'
import { useGetCustomerQuery } from '../customers/customersApiSlice'
import { useColorScheme } from '@mui/material/styles'
import { useTheme } from '@mui/material'

const InfoModal = () => {
  const { openModals, handleCloseModal, pageTitle, selectedRow: equipment } = useModalContext()
  const { data: distributor } = useGetDistributorQuery({ id: equipment.distributor })
  const { data: customer } = useGetCustomerQuery({ id: equipment.customer }, { skip: !equipment.customer })
  const theme = useTheme()
  const { mode } = useColorScheme()

  return (
    <Modal
      open={openModals.infoModal}
      onClose={() => handleCloseModal('infoModal')}
      closeAfterTransition>
      <Fade in={openModals.infoModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 1300,
            width: '100%',
            backgroundColor: theme.vars.palette.customBackground[mode],
            [theme.getColorSchemeSelector('dark')]: { boxShadow: '#444 0px 0px 0px 0.125rem' }
          }}>
          <Stack
            direction="row"
            p={1}>
            <Typography sx={{ lineHeight: '1', mr: 5, textWrap: 'nowrap', fontSize: '1.5rem', fontWeight: '500' }}>Equipment Information</Typography>
            <Grid
              sx={{ fontSize: '13px' }}
              container
              spacing={0}>
              <Grid xs={3}>
                <strong>Purchase Order #:</strong> {equipment?.purchaseOrder}
              </Grid>
              <Grid xs={3}>
                <strong>Registered:</strong> {equipment?.isRegistered ? 'Yes' : 'No'}
              </Grid>
              <Grid xs={3}>
                <strong>Pre-Registered:</strong> {equipment?.isPreRegistered ? 'Yes' : 'No'}
              </Grid>
              <Grid xs={3}>
                <strong>Recycled:</strong> {equipment?.isRecycled ? 'Yes' : 'No'}
              </Grid>
              <Grid xs={3}>
                <strong>Installation Date:</strong> {equipment?.installationDate ? dayjs(equipment?.installationDate).format('MM/DD/YYYY') : 'N/A'}
              </Grid>
              <Grid xs={3}>
                <strong>Registration Date:</strong> {equipment?.registrationDate ? dayjs(equipment?.registrationDate).format('MM/DD/YYYY') : 'N/A'}
              </Grid>
              <Grid xs={3}>
                <strong>Pre-Registration Date:</strong> {equipment?.preRegistrationDate ? dayjs(equipment?.preRegistrationDate).format('MM/DD/YYYY') : 'N/A'}
              </Grid>
              <Grid xs={3}>
                <strong>Expiration Date:</strong> {equipment?.expirationDate ? dayjs(equipment?.expirationDate).format('MM/DD/YYYY') : 'N/A'}
              </Grid>
            </Grid>
            <IconButton
              aria-label="Close"
              onClick={() => handleCloseModal('infoModal')}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                color: theme.vars.palette.black.main,
                [theme.getColorSchemeSelector('dark')]: {
                  color: '#fff'
                }
              }}>
              <CloseIcon fontSize="20px" />
            </IconButton>
          </Stack>
          <Box sx={{ fontSize: '14px', p: 0 }}>
            <Grid
              container
              columnSpacing={0}>
              <Grid
                sm={6}
                sx={{ backgroundColor: theme.vars.palette.maroon.dark, color: '#fff', px: 1, py: 0.25 }}>
                <Typography
                  component="h6"
                  sx={{ fontWeight: '500' }}>
                  Distributor
                </Typography>
              </Grid>
              <Grid
                sm={6}
                sx={{ backgroundColor: theme.vars.palette.seafoam.dark, color: '#fff', px: 1, py: 0.25 }}>
                <Typography
                  component="h6"
                  sx={{ fontWeight: '500' }}>
                  Customer
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              columnSpacing={1}
              sx={{ px: 1, py: 0.75 }}>
              <Grid sm={6}>
                {!distributor && (
                  <Grid container>
                    <Grid sm={12}>No Distributor</Grid>
                  </Grid>
                )}
                {distributor && (
                  <>
                    <Grid
                      container
                      columnSpacing={1}>
                      <Grid sm={6}>
                        <strong>Name:</strong>
                      </Grid>
                      <Grid sm={6}>{distributor?.name}</Grid>
                      <Grid sm={6}>
                        <strong>Address:</strong>
                      </Grid>
                      <Grid sm={6}>{distributor?.address}</Grid>
                      <Grid sm={6}>
                        <strong>City:</strong>
                      </Grid>
                      <Grid sm={6}>{distributor?.city}</Grid>
                      <Grid sm={6}>
                        <strong>State:</strong>
                      </Grid>
                      <Grid sm={6}>{distributor?.state}</Grid>
                      <Grid sm={6}>
                        <strong>Zip:</strong>
                      </Grid>
                      <Grid sm={6}>{distributor?.zip}</Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid sm={6}>
                {!customer && (
                  <Grid container>
                    <Grid sm={12}>No Customer</Grid>
                  </Grid>
                )}
                {customer && (
                  <>
                    <Grid
                      container
                      columnSpacing={1}>
                      <Grid sm={6}>
                        <strong>Name:</strong>
                      </Grid>
                      <Grid sm={6}>{customer?.name}</Grid>
                      <Grid sm={6}>
                        <strong>Address:</strong>
                      </Grid>
                      <Grid sm={6}>{customer?.address}</Grid>
                      <Grid sm={6}>
                        <strong>City:</strong>
                      </Grid>
                      <Grid sm={6}>{customer?.city}</Grid>
                      <Grid sm={6}>
                        <strong>State:</strong>
                      </Grid>
                      <Grid sm={6}>{customer?.state}</Grid>
                      <Grid sm={6}>
                        <strong>Zip:</strong>
                      </Grid>
                      <Grid sm={6}>{customer?.zip}</Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid sx={{ backgroundColor: theme.vars.palette.slate.dark, color: '#fff', px: 1, py: 0.25 }}>
              <Typography
                component="h6"
                sx={{ fontWeight: '500' }}>
                Equipment
              </Typography>
            </Grid>
            <Grid
              container
              columnSpacing={1}
              sx={{ px: 1, py: 0.75 }}>
              <Grid xs>
                <strong>Model:</strong> {equipment?.unitModel}
              </Grid>
              <Grid xs>
                <strong>Horsepower:</strong> {equipment?.compressorHorsepower}
              </Grid>
              <Grid xs>
                <strong>New Serial #:</strong> {equipment?.serialNumber}
              </Grid>
              <Grid xs>
                <strong>Old Serial #:</strong> {equipment?.serialNumberOld}
              </Grid>
              <Grid xs>
                <strong>Shipping Date:</strong> {dayjs(equipment?.shippingDate).format('MM/DD/YYYY') ?? 'N/A'}
              </Grid>
              <Grid xs="auto">
                <strong>Customer Type:</strong> {equipment?.customerType === '' || !equipment?.customerType ? 'N/A' : equipment?.customerType}
              </Grid>
            </Grid>
            {pageTitle === 'recycled' && (
              <>
                <Grid sx={{ backgroundColor: theme.vars.palette.black.dark, color: '#fff', px: 1, py: 0.25 }}>
                  <Typography
                    component="h6"
                    sx={{ fontWeight: '500' }}>
                    RMA
                  </Typography>
                </Grid>
                <Grid
                  container
                  columnSpacing={1}
                  sx={{ px: 1, py: 0.75 }}>
                  <Grid sm>
                    <strong>RMA Number:</strong> {equipment?.rmaNumber}
                  </Grid>
                  <Grid sm>
                    <strong>Issue Date:</strong> {equipment?.rmaIssueDate ? dayjs(equipment?.rmaIssueDate).format('MM/DD/YYYY') : ''}
                  </Grid>
                  <Grid sm>
                    <strong>Returned Date:</strong> {equipment?.rmaReturnedDate ? dayjs(equipment?.rmaReturnedDate).format('MM/DD/YYYY') : ''}
                  </Grid>
                </Grid>
              </>
            )}
            <Grid sx={{ backgroundColor: theme.vars.palette.success.main, color: '#fff', px: 1, py: 0.25 }}>
              <Typography
                component="h6"
                sx={{ fontWeight: '500' }}>
                Pre-Registrant
              </Typography>
            </Grid>
            <Grid
              container
              columnSpacing={1}
              sx={{ px: 1, py: 0.75 }}>
              <Grid sm={6}>
                <Typography
                  component="h6"
                  sx={{ fontWeight: '500' }}>
                  Registrant
                </Typography>
              </Grid>
              <Grid sm={6}>
                <Typography
                  component="h6"
                  sx={{ fontWeight: '500' }}>
                  Registrant
                </Typography>
              </Grid>
              <Grid sm={3}>
                <strong>Name:</strong>
                <br />
                {equipment?.preRegistrantFirstName ? (
                  <>
                    {equipment?.preRegistrantFirstName} {equipment?.preRegistrantLastName}
                  </>
                ) : (
                  'N/A'
                )}
              </Grid>
              <Grid sm={3}>
                <strong>E-mail address:</strong>
                <br />
                {equipment?.preRegistrantEmail ? <a href={`mailto:${equipment?.preRegistrantEmail}`}>{equipment?.preRegistrantEmail}</a> : 'N/A'}
              </Grid>
              <Grid sm={2}>
                <strong>Card Ordered:</strong>
                <br />
                {equipment?.preRegistrantCardOrdered ? dayjs(equipment?.preRegistrantCardOrdered).format('MM/DD/YYYY') : 'N/A'}
              </Grid>
              <Grid sm={2}>
                <strong>Money Loaded:</strong>
                <br />
                {equipment?.preRegistrantMoneyLoaded ? dayjs(equipment?.preRegistrantMoneyLoaded).format('MM/DD/YYYY') : 'N/A'}
              </Grid>
              <Grid sm={2}>
                <strong>Award:</strong>
                <br />${equipment?.preRegistrantAward ?? 0}
              </Grid>
            </Grid>
            <Grid sx={{ backgroundColor: 'black.dark', color: '#fff', px: 1, py: 0.25 }}>
              <Typography
                component="h6"
                sx={{ fontWeight: '500' }}>
                Registrant/Award Recipient
              </Typography>
            </Grid>
            <Grid
              container
              columnSpacing={1}
              sx={{ px: 1, py: 0.75 }}>
              <Grid sm={6}>
                <Typography
                  component="p"
                  sx={{ fontWeight: '500' }}>
                  Registrant
                </Typography>
              </Grid>
              <Grid sm={6}>
                <Typography
                  component="p"
                  sx={{ fontWeight: '500' }}>
                  Sales or Service Personnel
                </Typography>
              </Grid>
              <Grid sm={3}>
                <strong>Name:</strong>
                <br />
                {equipment?.registrantFirstName ? (
                  <>
                    {equipment?.registrantFirstName} {equipment?.registrantLastName}
                  </>
                ) : (
                  'N/A'
                )}
              </Grid>
              <Grid sm={3}>
                <strong>E-mail address:</strong>
                <br />
                {equipment?.registrantEmail ? <a href={`mailto:${equipment?.registrantEmail}`}>{equipment?.registrantEmail}</a> : 'N/A'}
              </Grid>
              <Grid sm={3}>
                <strong>Name:</strong>
                <br />
                {equipment?.salesFirstName ? (
                  <>
                    {equipment?.salesFirstName} {equipment?.salesLastName}
                  </>
                ) : (
                  'N/A'
                )}
              </Grid>
              <Grid sm={3}>
                <strong>E-mail address:</strong>
                <br />
                {equipment?.salesEmail ? <a href={`mailto:${equipment?.salesEmail}`}>{equipment?.salesEmail}</a> : 'N/A'}
              </Grid>
            </Grid>
            <Grid sx={{ backgroundColor: theme.vars.palette.eggplant.dark, color: '#fff', px: 1, py: 0.25 }}>
              <Typography
                component="h6"
                sx={{ fontWeight: '500' }}>
                Administration
              </Typography>
            </Grid>
            <Grid
              container
              columnSpacing={1}
              sx={{ px: 1, py: 0.75 }}>
              <Grid sm={6}>
                <Typography
                  component="p"
                  sx={{ fontWeight: '500' }}>
                  Registrant
                </Typography>
              </Grid>
              <Grid sm={6}>
                <Typography
                  component="p"
                  sx={{ fontWeight: '500' }}>
                  Sales or Service Personnel
                </Typography>
              </Grid>
              <Grid sm={2}>
                <strong>Card Ordered:</strong>
                <br />
                {equipment?.registrantCardOrdered ? dayjs(equipment?.registrantCardOrdered).format('MM/DD/YYYY') : 'N/A'}
              </Grid>
              <Grid sm={2}>
                <strong>Money Loaded:</strong>
                <br />
                {equipment?.registrantMoneyLoaded ? dayjs(equipment?.registrantMoneyLoaded).format('MM/DD/YYYY') : 'N/A'}
              </Grid>
              <Grid sm={2}>
                <strong>Award:</strong>
                <br />${equipment?.registrantAward ?? 0}
              </Grid>
              <Grid sm={2}>
                <strong>Card Ordered:</strong>
                <br />
                {equipment?.salesCardOrdered ? dayjs(equipment?.salesCardOrdered).format('MM/DD/YYYY') : 'N/A'}
              </Grid>
              <Grid sm={2}>
                <strong>Money Loaded:</strong>
                <br />
                {equipment?.salesMoneyLoaded ? dayjs(equipment?.salesMoneyLoaded).format('MM/DD/YYYY') : 'N/A'}
              </Grid>
              <Grid sm={2}>
                <strong>Award:</strong>
                <br />${equipment?.salesAward ?? 0}
              </Grid>
            </Grid>
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            p={1}>
            <Typography sx={{ fontSize: '12px' }}>
              Created {equipment?.createdUser && <>by {equipment?.createdUser}</>} on {dayjs(equipment?.createdAt).format('MM/DD/YYYY hh:mm:ss a')}
              <br />
              Last updated {equipment?.updatedUser && <>by {equipment?.updatedUser}</>} on {dayjs(equipment?.updatedAt).format('MM/DD/YYYY hh:mm:ss a')}
            </Typography>
            <Button
              variant="contained"
              size="small"
              color="cancel"
              onClick={() => handleCloseModal('infoModal')}
              type="button">
              Close
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}

export default InfoModal
