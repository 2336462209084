import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const unitsAdapter = createEntityAdapter({})

const initialState = unitsAdapter.getInitialState()

export const unitsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUnit: builder.query({
      query: ({ id }) => ({
        url: `/units/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result, error, id) => [{ type: 'Unit', id }]
    }),
    getUnits: builder.query({
      query: () => ({
        url: '/units',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Unit', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Unit', id }))]
        } else return [{ type: 'Unit', id: 'LIST' }]
      }
    }),
    getPaginatedUnits: builder.query({
      query: (params) => {
        const { page = 1, limit = 20, sorting = [], searchTerm = '' } = params
        return {
          url: '/units',
          params: { page, limit, columnSort: sorting.length > 0 ? sorting[0].id : '', columnType: sorting.length > 0 ? sorting[0].desc : '', searchTerm },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      },
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Unit', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Unit', id }))]
        } else return [{ type: 'Unit', id: 'LIST' }]
      }
    }),
    addNewUnit: builder.mutation({
      query: (initialUnit) => ({
        url: '/units',
        method: 'POST',
        body: {
          ...initialUnit
        }
      }),
      invalidatesTags: ['Unit']
    }),
    updateUnit: builder.mutation({
      query: (initialUnit) => ({
        url: '/units',
        method: 'PATCH',
        body: {
          ...initialUnit
        }
      }),
      invalidatesTags: ['Unit']
    }),
    deleteUnit: builder.mutation({
      query: ({ id }) => ({
        url: '/units',
        method: 'DELETE',
        body: { id }
      }),
      invalidatesTags: ['Unit']
    })
  })
})

export const { useGetUnitQuery, useLazyGetUnitQuery, useGetUnitsQuery, useGetPaginatedUnitsQuery, useAddNewUnitMutation, useUpdateUnitMutation, useDeleteUnitMutation } = unitsApiSlice

// returns the query result object
export const selectUnitsResults = unitsApiSlice.endpoints.getUnits.select()

// creates memoized selector
const selectUnitsData = createSelector(selectUnitsResults, (unitsResult) => unitsResult.data)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllUnits,
  selectById: selectUnitById,
  selectIds: selectUnitIds
  // Pass in a selector that returns the units slice of state
} = unitsAdapter.getSelectors((state) => selectUnitsData(state) ?? initialState)
