import { GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid'

const CustomToolbar = ({ reportType }) => {
  const d = new Date()
  const currentDay = ('0' + d.getDate()).slice(-2)
  const currentMonth = ('0' + (d.getMonth() + 1)).slice(-2)
  let totalsColumns = []

  if (reportType && reportType === 'Totals Expanded') {
    totalsColumns = [
      'monthYear',
      'allUnitsShipped',
      'allUnitsRegistered',
      'replacedCompetitorOwsCondition1',
      'replacedCompetitorOwsCondition2',
      'replacedCompetitorOwsCondition3',
      'replacedCompetitorOwsOem',
      'replacedCompetitorOwsTotal',
      'crpReplacementCondition1',
      'crpReplacementCondition2',
      'crpReplacementCondition3',
      'crpReplacementOem',
      'crpReplacementTotal',
      'firstTimeOwsCustomerCondition1',
      'firstTimeOwsCustomerCondition2',
      'firstTimeOwsCustomerCondition3',
      'firstTimeOwsCustomerOem',
      'firstTimeOwsCustomerTotal',
      'dontKnow',
      'Replace Competitor OWS',
      'CRP Replacement',
      'First-Time OWS Customer'
    ]
  }

  if (reportType === 'Totals') {
    totalsColumns = ['monthYear', 'allUnitsShipped', 'allUnitsRegistered', 'replacedCompetitorOws', 'crpReplacement', 'firstTimeOwsCustomer', 'dontKnow']
  }

  const csvOptions = {
    allColumns: false,
    fileName: `${reportType.replace(/\s/g, '-')}-Report-${d.getFullYear()}-${currentMonth}-${currentDay}`,
    utf8WithBom: true,
    includeColumnGroupsHeaders: true
  }

  if (reportType === 'Totals' || reportType === 'Totals Expanded') {
    csvOptions.fields = totalsColumns
  }

  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={csvOptions} />
      {!(reportType === 'Totals' || reportType === 'Totals Expanded') && <GridToolbarColumnsButton />}
      {!(reportType === 'Totals' || reportType === 'Totals Expanded') && <GridToolbarFilterButton />}
    </GridToolbarContainer>
  )
}

export default CustomToolbar
