import { useCallback, useState } from 'react'
import UsersHeader from './UsersHeader'
import UsersList from './UsersList'

const UsersPage = () => {
  const [page, setPage] = useState(1)

  const handleSetPage = useCallback(
    (newPage) => {
      setPage(newPage)
    },
    [setPage]
  )

  return (
    <>
      <UsersHeader handleSetPage={handleSetPage} />
      <UsersList
        page={page}
        handleSetPage={handleSetPage}
      />
    </>
  )
}

export default UsersPage
