import { useCallback, useState } from 'react'
import EquipmentsHeader from './EquipmentsHeader'
import EquipmentsList from './EquipmentsList'

const EquipmentsPage = () => {
  const [page, setPage] = useState(1)

  const handleSetPage = useCallback(
    (newPage) => {
      setPage(newPage)
    },
    [setPage]
  )

  return (
    <>
      <EquipmentsHeader handleSetPage={handleSetPage} />
      <EquipmentsList
        page={page}
        handleSetPage={handleSetPage}
      />
    </>
  )
}

export default EquipmentsPage
