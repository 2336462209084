import { Outlet } from 'react-router-dom'
import DashHeader from './DashHeader'
import { Container } from '@mui/material'

const DashLayout = () => {
  return (
    <>
      <DashHeader />
      <Container
        maxWidth="false"
        disableGutters={true}
        sx={{
          px: 1
        }}>
        <Outlet />
      </Container>
    </>
  )
}
export default DashLayout
