import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { store } from '../../app/store'
import { customersApiSlice } from '../customers/customersApiSlice'
import { distributorsApiSlice } from '../distributors/distributorsApiSlice'
import { equipmentsApiSlice } from '../equipments/equipmentsApiSlice'
import { extUsersApiSlice } from '../users/extUsersApiSlice'
import { logsApiSlice } from '../logview/logsApiSlice'
import { unitsApiSlice } from '../units/unitsApiSlice'
import { usersApiSlice } from '../users/usersApiSlice'

const Prefetch = () => {
  useEffect(() => {
    store.dispatch(customersApiSlice.util.prefetch('getCustomers', 'customersList', { force: true }))
    store.dispatch(customersApiSlice.util.prefetch('getPaginatedCustomers', 'customersList', { force: true }))
    store.dispatch(distributorsApiSlice.util.prefetch('getDistributors', 'distributorsList', { force: true }))
    store.dispatch(distributorsApiSlice.util.prefetch('getPaginatedDistributors', 'distributorsList', { force: true }))
    store.dispatch(equipmentsApiSlice.util.prefetch('getEquipments', 'equipmentsList', { force: true }))
    store.dispatch(equipmentsApiSlice.util.prefetch('getPaginatedEquipments', 'paginatedEquipmentsList', { force: true }))
    store.dispatch(extUsersApiSlice.util.prefetch('getExtUsers', 'extUsersList', { force: true }))
    store.dispatch(extUsersApiSlice.util.prefetch('getPaginatedExtUsers', 'extUsersList', { force: true }))
    store.dispatch(logsApiSlice.util.prefetch('getLogs', 'logsList', { force: true }))
    store.dispatch(logsApiSlice.util.prefetch('getPaginatedLogs', 'logsList', { force: true }))
    store.dispatch(unitsApiSlice.util.prefetch('getUnits', 'unitsList', { force: true }))
    store.dispatch(unitsApiSlice.util.prefetch('getPaginatedUnits', 'unitsList', { force: true }))
    store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersList', { force: true }))
    store.dispatch(usersApiSlice.util.prefetch('getPaginatedUsers', 'usersList', { force: true }))
  }, [])

  return <Outlet />
}
export default Prefetch
