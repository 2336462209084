import { useEffect, useState } from 'react'
import { styled, useColorScheme } from '@mui/material/styles'
import WindowedSelect from 'react-windowed-select'
import { createFilter } from 'react-select'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'

const generateUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}

const StyledWindowedSelect = styled('div')(({ theme, isMulti, mode, isInModal }) => ({
  '& .react-select__input': {
    color: '#333 !important',
    [theme.getColorSchemeSelector('dark')]: { color: '#fff !important' }
  },
  '& .react-select__control': {
    borderRadius: 4,
    backgroundColor: isInModal ? theme.vars.palette.inputBackground.light : '#fff',
    outline: '1px solid #ccc !important',
    border: 'none',
    width: '100%',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    maxHeight: !isMulti && '31px',
    minHeight: !isMulti && '31px',
    height: !isMulti && '31px',
    fontSize: '15px',
    '&--is-focused': {
      boxShadow: `rgba(${theme.vars.palette.primary.mainChannel} / 0.25) 0 0 0 0.25rem`,
      [theme.getColorSchemeSelector('dark')]: {
        boxShadow: `rgba(${theme.vars.palette.primary.lightChannel} / 0.25) 0 0 0 0.25rem`
      }
    },
    [theme.getColorSchemeSelector('dark')]: {
      backgroundColor: isInModal ? theme.vars.palette.inputBackground.dark : theme.vars.palette.customBackground.dark,
      outline: '1px solid #555 !important'
    }
  },
  '& .react-select__input-container': {
    margin: 0
  },
  '& .react-select__indicator-separator': {
    display: 'none'
  },
  '& .react-select__value-container': {
    height: !isMulti && '31px',
    padding: '0 6px'
  },
  '& .react-select__single-value': {
    color: theme.vars.palette.inputLabel.main,
    [theme.getColorSchemeSelector('dark')]: { color: theme.vars.palette.inputLabel.dark }
  },
  '& .react-select__multi-value': {
    [theme.getColorSchemeSelector('dark')]: {
      color: '#fff',
      backgroundColor: theme.vars.palette.customBackground.dark
    },
    '&__label': {
      [theme.getColorSchemeSelector('dark')]: {
        color: '#fff'
      }
    }
  },
  '& .react-select__menu': {
    backgroundColor: theme.vars.palette.inputBackground.main,
    [theme.getColorSchemeSelector('dark')]: {
      backgroundCOlor: theme.vars.palette.inputBackground.light
    },
    '&-list': {
      [theme.getColorSchemeSelector('dark')]: {
        backgroundColor: '#666'
      }
    }
  },
  '& .react-select__indicators': {
    height: !isMulti && '30px'
  },
  '& label': {
    transition: 'top 150ms cubic-bezier(0.4, 0, 0.2, 1),left 150ms cubic-bezier(0.4, 0, 0.2, 1), color 300ms cubic-bezier(0.4, 0, 0.2, 1)',
    fontWeight: '600',
    fontSize: '.85rem',
    lineHeight: 1,
    marginBottom: 4,
    display: 'block',
    color: theme.vars.palette.inputLabel[mode],
    '&.focused': {
      color: theme.vars.palette.primary.main,
      [theme.getColorSchemeSelector('dark')]: {
        color: theme.vars.palette.primary.light
      }
    },
    '& span': {
      fontSize: '12px',
      lineHeight: 1,
      display: 'inline-block'
    }
  }
}))

// color #ccc is for dark mode
// color #999 is for light mode

const formatOptionLabel =
  (mode) =>
  ({ label, customLabel }) => {
    return (
      <Stack
        direction="row"
        sx={{ display: 'flex', gap: '.75rem', alignItems: 'center' }}>
        <Box
          component="span"
          sx={{ whiteSpace: 'nowrap' }}>
          {label}
        </Box>
        <Box
          component="span"
          sx={{ color: '#999', fontSize: '.75rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', '&:focus': { color: 'red' } }}>
          {customLabel}
        </Box>
      </Stack>
    )
  }

const multiFormatOptionLabel =
  (mode) =>
  ({ label, customLabel }) => {
    const [name] = label.split('|')
    return (
      <Stack
        component="div"
        direction="row"
        sx={{ display: 'flex', gap: '.75rem', alignItems: 'center', backgroundColor: 'transparent' }}>
        <Box
          component="span"
          sx={{ whiteSpace: 'nowrap' }}>
          {name}
        </Box>
        <Box
          component="span"
          sx={{ color: mode === 'dark' ? '#ccc' : '#999', fontSize: '.75rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', '&:focus': { color: '#eee' } }}>
          {customLabel}
        </Box>
      </Stack>
    )
  }

const CustomWindowedSelect = ({ resetKey, options, titleLabel, defaultValue, onChange, disabled, isMulti, conditonalLabel, required, isInModal }) => {
  const { mode } = useColorScheme()
  const theme = useTheme()
  const uniqueId = generateUniqueId()
  const [inputFocused, setInputFocused] = useState(false)
  const [selectedOption, setSelectedOption] = useState(defaultValue)

  useEffect(() => {
    setSelectedOption('')
  }, [defaultValue, resetKey])

  const handleOnChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    onChange(selectedOption)
  }

  const curriedFormatOptionLabel = formatOptionLabel(mode)
  const curriedMultiFormatOptionLabel = multiFormatOptionLabel(mode)

  return (
    <StyledWindowedSelect
      disabled={disabled}
      isMulti={isMulti}
      mode={mode}
      isInModal={isInModal}>
      {titleLabel && (
        <label
          htmlFor={uniqueId}
          className={inputFocused ? 'focused' : ''}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}>
          {titleLabel}{' '}
          {conditonalLabel && !selectedOption && (
            <Typography
              component="span"
              sx={{ color: theme.vars.palette.error.main }}>
              {conditonalLabel}
            </Typography>
          )}
        </label>
      )}
      {options ? (
        <WindowedSelect
          inputId={uniqueId}
          classNamePrefix="react-select"
          onChange={handleOnChange}
          options={options}
          filterOption={createFilter({ ignoreAccents: false })}
          formatOptionLabel={isMulti ? curriedMultiFormatOptionLabel : curriedFormatOptionLabel}
          isDisabled={disabled}
          defaultValue={defaultValue}
          isClearable
          isMulti={isMulti}
          placeholder=""
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          required={required}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: mode === 'dark' ? '#444444' : '#dfebfe'
            }
          })}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={38}
          sx={{ borderRadius: 4 }}
        />
      )}
    </StyledWindowedSelect>
  )
}

export default CustomWindowedSelect
