import { memo, useCallback, useMemo, useState } from 'react'
import { useGetPaginatedUnitsQuery } from './unitsApiSlice'
import useAuth from '../../hooks/useAuth'
import useTitle from '../../hooks/useTitle'
import BeatLoader from 'react-spinners/BeatLoader'
import CustomTableFooter from '../../components/CustomTableFooter'
import { useModalContext } from '../../hooks/useModalContext'
import { useSearchContext } from '../../hooks/useSearchContext'
import usePageTitle from '../../hooks/usePageTitle'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Checkbox from '@mui/material/Checkbox'
import { BsTrash } from 'react-icons/bs'
import { BsPencil } from 'react-icons/bs'
import { useTheme } from '@mui/material'
import CustomTable from '../../components/CustomTable'
import CustomActionButtonGroup from '../../components/CustomActionButtonGroup'

const UnitsList = memo(({ page, handleSetPage }) => {
  const theme = useTheme()
  useTitle('Units | BILL Dashboard')
  usePageTitle('unit')

  const { handleOpenModal, setSelectedRow } = useModalContext()
  const { searchTerm } = useSearchContext()

  const [sorting, setSorting] = useState([{ id: 'model', desc: false }])
  const [limit, setLimit] = useState(20)
  const { role } = useAuth()

  const handleDeleteClick = useCallback(
    (selected) => {
      handleOpenModal('deleteModal')
      setSelectedRow(selected)
    },
    [handleOpenModal, setSelectedRow]
  )

  const handleEditClick = useCallback(
    (selected) => {
      handleOpenModal('updateModal')
      setSelectedRow(selected)
    },
    [handleOpenModal, setSelectedRow]
  )

  const handleLimitChange = useCallback(
    (e) => {
      handleSetPage(1)
      setLimit(+e.target.value)
    },
    [handleSetPage, setLimit]
  )

  const handleSetSorting = useCallback(
    (newSorting) => {
      handleSetPage(1)
      setSorting(newSorting)
    },
    [handleSetPage, setSorting]
  )

  const {
    data: units,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetPaginatedUnitsQuery({ page, limit, sorting, searchTerm }, 'unitsList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  const columns = useMemo(
    () => [
      {
        accessorKey: 'model',
        id: 'model',
        header: 'Model'
      },
      {
        accessorKey: 'awardValue',
        id: 'awardValue',
        header: 'Award Value'
      },
      {
        accessorKey: 'distributorName',
        id: 'distributorName',
        header: 'Licensee'
      },
      {
        accessorKey: 'isActive',
        id: 'isActive',
        size: 50,
        header: 'Active',
        enableSorting: false,
        muiTableHeadCellProps: { align: 'center' },
        Cell: ({ row }) => (
          <Checkbox
            checked={row.getValue('isActive')}
            readOnly
            disabled
            icon={<CheckBoxOutlineBlankIcon sx={{ fontSize: 15, color: 'lightgray' }} />}
            checkedIcon={
              <CheckBoxIcon
                sx={{ fontSize: 15 }}
                color="slate"
              />
            }
            sx={{
              fontSize: 20,
              display: 'flex',
              alignItems: 'center',
              p: 0
            }}
          />
        )
      },
      ...(role === 'admin' || role === 'superuser'
        ? [
            {
              header: 'Actions',
              id: 'actions',
              size: 60,
              minSize: 60,
              maxSize: 75,
              muiTableHeadCellProps: { align: 'right' },
              muiTableBodyCellProps: { sx: { p: 0, m: 0 } },
              Cell: ({ row }) => (
                <CustomActionButtonGroup
                  row={row}
                  buttons={[
                    {
                      title: 'Edit',
                      color: 'primary',
                      onClick: handleEditClick,
                      icon: <BsPencil />,
                      visible: true
                    },
                    {
                      title: 'Delete',
                      color: 'error',
                      onClick: handleDeleteClick,
                      icon: <BsTrash />,
                      visible: true
                    }
                  ]}
                />
              )
            }
          ]
        : [])
    ],
    [handleDeleteClick, handleEditClick, role]
  )

  if (isLoading)
    return (
      <BeatLoader
        color={theme.vars.palette.slate.main}
        size={20}
      />
    )

  if (isError) return <p className="errmsg">{error?.data?.message}</p>

  if (isSuccess) {
    const { docs } = units

    return (
      <>
        <CustomTable
          columns={columns}
          docs={docs}
          isError={isError}
          sorting={sorting}
          handleSetSorting={handleSetSorting}
          color="slate"
        />
        <CustomTableFooter
          page={page}
          handleSetPage={handleSetPage}
          data={units}
          limit={limit}
          handleLimitChange={handleLimitChange}
          buttonColor="slate"
        />
      </>
    )
  }
})

export default UnitsList
