import { useState } from 'react'
import { styled, useColorScheme } from '@mui/material/styles'
import DatePicker from 'react-datepicker'
import EventIcon from '@mui/icons-material/Event'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

const generateUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}

const StyledDatePicker = styled('div')(
  ({ theme, disabled, mode, isInModal }) => ({
    '& label': {
      color: theme.vars.palette.inputLabel[mode],
      transition: theme.transitions.create(['color']),
      '&.focused': {
        color: theme.vars.palette.primary.main,
        [theme.getColorSchemeSelector('dark')]: {
          color: theme.vars.palette.primary.light
        }
      },
      ...(disabled && {
        color: '#666'
      })
    },
    '& .react-datepicker': {
      outline: '1px solid #ccc',
      [theme.getColorSchemeSelector('dark')]: {
        outline: '1px solid #555'
      }
    },
    '.react-datepicker__month-container, .react-datepicker__header, .react-datepicker__month': {
      background: theme.vars.palette.inputBackground[mode],
      '& *': {
        color: theme.vars.palette.inputText[mode]
      }
    },
    '& input': {
      backgroundColor: isInModal ? theme.vars.palette.inputBackground.light : '#fff',
      outline: '1px solid #ccc',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      color: theme.vars.palette.inputText[mode],
      '&:focus': {
        boxShadow: `rgba(${theme.vars.palette.primary.mainChannel} / 0.25) 0 0 0 0.25rem`,
        [theme.getColorSchemeSelector('dark')]: {
          boxShadow: `rgba(${theme.vars.palette.primary.lightChannel} / 0.25) 0 0 0 0.25rem`
        }
      },
      ...(disabled && {
        color: '#666'
      }),
      [theme.getColorSchemeSelector('dark')]: {
        backgroundColor: isInModal ? theme.vars.palette.inputBackground.dark : theme.vars.palette.customBackground.dark,
        outline: '1px solid #555'
      }
    },
    '.react-datepicker__day': {
      '&:hover': {
        backgroundColor: theme.vars.palette.primary.light
      }
    },
    '& .react-datepicker__calendar-icon': {
      color: theme.vars.palette.inputText[mode],
      '&:hover': {
        color: theme.vars.palette.primary.main
      }
    },
    '.react-datepicker__close-icon': {
      '&:after': {
        color: theme.vars.palette.inputText[mode]
      },
      '&:hover': {
        '&:after': {
          color: theme.vars.palette.primary.main
        }
      }
    }
  }),
  ({ disabled }) => ({
    pointerEvents: disabled ? 'none' : 'auto'
  })
)

const CustomMultiDatePicker = ({ label, startDate, endDate, onChange, disabled, required, isInModal }) => {
  const { mode } = useColorScheme()
  const theme = useTheme()
  const [inputFocused, setInputFocused] = useState(false)
  const minDate = new Date('2016-01-01T00:00:00')
  const uniqueId = generateUniqueId()

  const handleBlur = (e) => {
    if (!e.target.value) {
      onChange([])
    }
    setInputFocused(false)
  }
  const handleFocus = () => setInputFocused(true)

  return (
    <StyledDatePicker
      disabled={disabled}
      className="styled-datepicker"
      mode={mode}
      isInModal={isInModal}>
      <label
        htmlFor={uniqueId}
        className={inputFocused ? 'focused' : ''}>
        {label}
        {required && (
          <Typography
            component="span"
            sx={{ color: theme.vars.palette.error.main, lineHeight: 1, fontSize: '.85rem', ml: 0.125 }}>
            *
          </Typography>
        )}
      </label>
      <DatePicker
        id={uniqueId}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        minDate={minDate}
        isClearable={true}
        placeholderText="mm/dd/yyyy - mm/dd/yyyy"
        showIcon
        toggleCalendarOnIconClick
        showPopperArrow={false}
        monthsShown={2}
        icon={<EventIcon />}
      />
    </StyledDatePicker>
  )
}

export default CustomMultiDatePicker
