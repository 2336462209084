import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useModalContext } from '../../hooks/useModalContext'
import { useTheme } from '@mui/material'

const CreateModal = ({ children }) => {
  const { handleCloseModal, openModals, pageTitle } = useModalContext()
  const theme = useTheme()

  return (
    <Modal
      open={openModals.createModal}
      onClose={() => handleCloseModal('createModal')}
      closeAfterTransition>
      <Fade in={openModals.createModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: pageTitle === 'user' ? 500 : 800, boxShadow: 24, backgroundColor: theme.vars.palette.customBackground[theme.palette.mode] }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ backgroundColor: theme.vars.palette.customBackground[pageTitle], py: 1, pl: 2, pr: 1, color: 'white' }}>
            <Typography
              component="h4"
              sx={{ textTransform: 'capitalize', fontSize: '24px', fontWeight: '500' }}>
              Add new {pageTitle === 'externaluser' ? 'DB Access' : pageTitle}
            </Typography>
            <IconButton
              aria-label="Close"
              onClick={() => handleCloseModal('createModal')}
              sx={{
                color: 'white'
              }}>
              <CloseIcon fontSize="20px" />
            </IconButton>
          </Stack>
          {children}
        </Box>
      </Fade>
    </Modal>
  )
}
export default CreateModal
