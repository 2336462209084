import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles.scss'
import App from './App'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { ModalContextProvider } from './features/context/ModalContext'
import { SearchContextProvider } from './features/context/SearchContext'
import { UserContextProvider } from './features/context/UserContext'
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { theme } from './theme'

if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'stage') disableReactDevTools()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <ModalContextProvider>
        <SearchContextProvider>
          <UserContextProvider>
            <Provider store={store}>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/*"
                    element={<App />}
                  />
                </Routes>
              </BrowserRouter>
            </Provider>
          </UserContextProvider>
        </SearchContextProvider>
      </ModalContextProvider>
    </CssVarsProvider>
  </React.StrictMode>
)
