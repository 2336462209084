import { createContext, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

export const SearchContext = createContext()
SearchContext.displayName = 'SearchContext'

export const SearchContextProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [debouncedSearchTerm] = useDebounce(inputValue, 500)

  const handleSearchChange = (searchValue) => {
    setInputValue(searchValue)
  }

  useEffect(() => {
    setSearchTerm(debouncedSearchTerm)
  }, [setSearchTerm, debouncedSearchTerm])

  const handleClearSearch = () => {
    setSearchTerm('')
    setInputValue('')
  }

  const value = {
    inputValue,
    searchTerm,
    handleSearchChange,
    handleClearSearch
  }
  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
}
