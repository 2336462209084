import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const reportsAdapter = createEntityAdapter({})

const initialState = reportsAdapter.getInitialState()

export const reportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReport: builder.query({
      query: ({ id }) => ({
        url: `/reports/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result, error, id) => [{ type: 'Report', id }]
    }),
    getReports: builder.query({
      query: () => ({
        url: '/reports',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Report', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Report', id }))]
        } else return [{ type: 'Report', id: 'LIST' }]
      }
    }),
    getPaginatedReports: builder.query({
      query: (params) => {
        const { page = 1, limit = 20, sorting = [], searchTerm = '' } = params
        return {
          url: '/reports',
          params: { page, limit, columnSort: sorting.length > 0 ? sorting[0].id : '', columnType: sorting.length > 0 ? sorting[0].desc : '', searchTerm },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      },
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Report', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Report', id }))]
        } else return [{ type: 'Report', id: 'LIST' }]
      }
    }),
    getReportTotals: builder.query({
      query: ({ year }) => {
        return {
          url: '/report-totals',
          params: { year },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      }
    })
  })
})

export const { useGetReportQuery, useGetReportsQuery, useGetPaginatedReportsQuery, useLazyGetReportTotalsQuery } = reportsApiSlice

// returns the query result object
export const selectReportsResults = reportsApiSlice.endpoints.getReports.select()

// creates memoized selector
const selectReportsData = createSelector(selectReportsResults, (reportsResult) => reportsResult.data)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllReports,
  selectById: selectReportById,
  selectIds: selectReportIds
  // Pass in a selector that returns the reports slice of state
} = reportsAdapter.getSelectors((state) => selectReportsData(state) ?? initialState)
