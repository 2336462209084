import { useCallback, useState } from 'react'
import LogsHeader from './LogsHeader'
import LogsList from './LogsList'

const LogsPage = () => {
  const [page, setPage] = useState(1)

  const handleSetPage = useCallback(
    (newPage) => {
      setPage(newPage)
    },
    [setPage]
  )

  return (
    <>
      <LogsHeader handleSetPage={handleSetPage} />
      <LogsList
        page={page}
        handleSetPage={handleSetPage}
      />
    </>
  )
}

export default LogsPage
