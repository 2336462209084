import { createContext, useState } from 'react'

export const ModalContext = createContext()
ModalContext.displayName = 'ModalContext'

export const ModalContextProvider = ({ children }) => {
  const [selectedRow, setSelectedRow] = useState({})
  const [pageTitle, setPageTitle] = useState('')
  const [openModals, setOpenModals] = useState({
    createModal: false,
    updateModal: false,
    infoModal: false,
    deleteModal: false,
    equipmentModal: false,
    passwordModal: false,
    confirmDeleteModal: false
  })
  const [passwordModal, setPasswordModal] = useState(false)

  const handleCloseModal = (modalType) => {
    setOpenModals((prevState) => ({
      ...prevState,
      [modalType]: false
    }))
  }
  const handleOpenModal = (modalType) => {
    setOpenModals((prevState) => ({
      ...prevState,
      [modalType]: true
    }))
  }

  const handleShowPasswordModal = () => setPasswordModal(true)
  const handleClosePasswordModal = () => setPasswordModal(false)

  const value = {
    handleCloseModal,
    handleClosePasswordModal,
    handleOpenModal,
    handleShowPasswordModal,
    openModals,
    pageTitle,
    passwordModal,
    selectedRow,
    setPageTitle,
    setSelectedRow
  }
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}
