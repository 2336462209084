import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useAddNewDistributorMutation } from '../distributors/distributorsApiSlice'
import { useModalContext } from '../../hooks/useModalContext'
import { toast } from 'react-toastify'
import CustomTextInput from '../../components/CustomTextInput'
import CustomPhoneInput from '../../components/CustomPhoneInput'
import { useTheme } from '@mui/material'

const CreateDistributor = () => {
  const [addNewDistributor] = useAddNewDistributorMutation()
  const { handleCloseModal } = useModalContext()
  const [pending, setIsPending] = useState(false)
  const theme = useTheme()

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [phone2, setPhone2] = useState('')
  const [fax, setFax] = useState('')
  const [email, setEmail] = useState('')
  const [isLicensee, setIsLicensee] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsPending(true)

    try {
      await addNewDistributor({ name, address, address2, city, state, zip, country, phone, phone2, fax, email, isLicensee })
      toast.success(
        <>
          Distributor <strong>{name}</strong> has been created.
        </>
      )
    } catch (error) {
      toast.error(
        <>
          Distributor <strong>{name}</strong> was NOT created.
        </>
      )
    }
    handleCloseModal('createModal')
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ px: 2, pb: 1, pt: 2 }}>
        <Grid
          container
          columnSpacing={2}>
          <Grid
            sm={12}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={name}
              onChange={setName}
              label="Name"
              name="name"
              required={true}
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={address}
              onChange={setAddress}
              label="Address"
              name="address"
              required={true}
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={address2}
              onChange={setAddress2}
              label="Address 2"
              name="address2"
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={city}
              onChange={setCity}
              label="City"
              name="city"
              required={true}
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={state}
              onChange={setState}
              label="State"
              name="state"
              required={true}
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={zip}
              onChange={setZip}
              label="Zip"
              name="zip"
              required={true}
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={country}
              onChange={setCountry}
              label="Country"
              name="country"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomPhoneInput
              isInModal={true}
              value={phone}
              onChange={setPhone}
              label="Phone"
              name="phone"
              required={true}
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomPhoneInput
              isInModal={true}
              value={phone2}
              onChange={setPhone2}
              label="Phone 2"
              name="phone2"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={fax}
              onChange={setFax}
              label="Fax"
              name="fax"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={email}
              onChange={setEmail}
              label="Email"
              name="email"
              type="email"
              required={true}
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ pr: 2, pl: 1, pb: 2 }}>
        <FormControlLabel
          sx={{ mr: 'auto', mb: 0, ml: 0 }}
          control={
            <Checkbox
              size="small"
              sx={{ '&.Mui-checked': { color: theme.vars.palette.maroon.dark, [theme.getColorSchemeSelector('dark')]: { color: theme.vars.palette.maroon.main } } }}
            />
          }
          label={<Typography variant="body1">Licensee</Typography>}
          labelPlacement="end"
          id="isLicenseeUpdate"
          onChange={(e) => setIsLicensee(e.target.checked)}
          checked={isLicensee}
        />
        <Stack
          direction="row"
          spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="cancel"
            onClick={() => handleCloseModal('createModal')}
            type="button">
            Cancel
          </Button>
          {!pending && (
            <Button
              variant="contained"
              size="small"
              type="submit"
              disabled={name.length === 0 || address.length === 0 || city.length === 0 || state.length === 0 || zip.length === 0 || phone.length === 0 || email.length === 0}
              sx={{
                backgroundColor: theme.vars.palette.maroon.dark,
                '&:hover:not([disabled])': {
                  backgroundColor: theme.vars.palette.maroon.darker
                },
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all'
                }
              }}>
              Add Distributor
            </Button>
          )}
          {pending && (
            <Button
              variant="contained"
              size="small"
              type="button"
              disabled>
              <CircularProgress
                sx={{ color: theme.vars.palette.cancel.light, mr: 0.5 }}
                size={18}
              />
              Adding...
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
export default CreateDistributor
