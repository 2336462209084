import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const customersAdapter = createEntityAdapter({})

const initialState = customersAdapter.getInitialState()

export const customersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomer: builder.query({
      query: ({ id }) => ({
        url: `/customers/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result, error, id) => [{ type: 'Customer', id }]
    }),
    getCustomers: builder.query({
      query: () => ({
        url: '/customers',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Customer', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Customer', id }))]
        } else return [{ type: 'Customer', id: 'LIST' }]
      }
    }),
    getPaginatedCustomers: builder.query({
      query: (params) => {
        const { page = 1, limit = 20, sorting = [], searchTerm = '' } = params
        return {
          url: '/customers',
          params: { page, limit, columnSort: sorting.length > 0 ? sorting[0].id : '', columnType: sorting.length > 0 ? sorting[0].desc : '', searchTerm },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      },
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Customer', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Customer', id }))]
        } else return [{ type: 'Customer', id: 'LIST' }]
      }
    }),
    addNewCustomer: builder.mutation({
      query: (initialCustomer) => ({
        url: '/customers',
        method: 'POST',
        body: {
          ...initialCustomer
        }
      }),
      invalidatesTags: ['Customer']
    }),
    updateCustomer: builder.mutation({
      query: (initialCustomer) => ({
        url: '/customers',
        method: 'PATCH',
        body: {
          ...initialCustomer
        }
      }),
      invalidatesTags: ['Customer']
    }),
    deleteCustomer: builder.mutation({
      query: ({ id }) => ({
        url: '/customers',
        method: 'DELETE',
        body: { id }
      }),
      invalidatesTags: ['Customer']
    })
  })
})

export const { useGetCustomerQuery, useGetCustomersQuery, useGetPaginatedCustomersQuery, useAddNewCustomerMutation, useUpdateCustomerMutation, useDeleteCustomerMutation } = customersApiSlice

// returns the query result object
export const selectCustomersResults = customersApiSlice.endpoints.getCustomers.select()

// creates memoized selector
const selectCustomersData = createSelector(selectCustomersResults, (customersResult) => customersResult.data)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllCustomers,
  selectById: selectCustomerById,
  selectIds: selectCustomerIds
  // Pass in a selector that returns the customers slice of state
} = customersAdapter.getSelectors((state) => selectCustomersData(state) ?? initialState)
