import { useEffect } from 'react'
import { useModalContext } from './useModalContext'

const usePageTitle = (pageTitle) => {
  const { setPageTitle } = useModalContext()

  useEffect(() => {
    setPageTitle(pageTitle)
  }, [setPageTitle, pageTitle])
}

export default usePageTitle
