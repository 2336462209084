import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useUpdateCustomerMutation } from '../customers/customersApiSlice'
import { useModalContext } from '../../hooks/useModalContext'
import { toast } from 'react-toastify'
import CustomTextInput from '../../components/CustomTextInput'
import CustomPhoneInput from '../../components/CustomPhoneInput'
import { useTheme } from '@mui/material'

const UpdateCustomerForm = () => {
  const formatPhoneNumber = (phoneNumber) => {
    const unformattedPhoneNumber = phoneNumber.replace(/\D/g, '')
    return `+1${unformattedPhoneNumber}`
  }

  const [updateCustomer] = useUpdateCustomerMutation()
  const { handleCloseModal, selectedRow: customer, setSelectedRow } = useModalContext()
  const theme = useTheme()

  const [pending, setPending] = useState(false)
  const [name, setName] = useState(customer?.name)
  const [address, setAddress] = useState(customer?.address)
  const [address2, setAddress2] = useState(customer?.address2)
  const [city, setCity] = useState(customer?.city)
  const [state, setState] = useState(customer?.state)
  const [zip, setZip] = useState(customer?.zip)
  const [country, setCountry] = useState(customer?.country)
  const [phone, setPhone] = useState(formatPhoneNumber(customer?.phone))
  const [phone2, setPhone2] = useState(customer?.phone2 ? formatPhoneNumber(customer?.phone2) : '')
  const [fax, setFax] = useState(customer?.fax ? formatPhoneNumber(customer?.fax) : '')
  const [email, setEmail] = useState(customer?.email)
  const [isActive, setIsActive] = useState(customer?.isActive ? customer.isActive : false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setPending(true)
    try {
      await updateCustomer({
        id: customer.id,
        name,
        address,
        address2,
        city,
        state,
        zip,
        country,
        phone,
        phone2,
        fax,
        email,
        isActive
      }).unwrap()
      toast.info(
        <>
          Customer <strong>{name}</strong> has been updated.
        </>
      )
    } catch (error) {
      toast.error(
        <>
          Customer <strong>{name}</strong> was not updated. {error.data.message}.
        </>
      )
    }
    handleCloseModal('updateModal')
  }

  const handleCancel = () => {
    setSelectedRow({})
    handleCloseModal('updateModal')
  }

  const created = customer.createdAt ? new Date(customer.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) : 'N/A'
  const updated = customer.updatedAt ? new Date(customer.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) : 'N/A'

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ px: 2, py: 1 }}>
        <Grid
          container
          columnSpacing={2}>
          <Grid
            sm={12}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={name}
              onChange={setName}
              label="Name"
              name="name"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={address}
              onChange={setAddress}
              label="Address"
              name="address"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={address2}
              onChange={setAddress2}
              label="Address 2"
              name="address2"
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={city}
              onChange={setCity}
              label="City"
              name="city"
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={state}
              onChange={setState}
              label="State"
              name="state"
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={zip}
              onChange={setZip}
              label="Zip"
              name="zip"
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={country}
              onChange={setCountry}
              label="Country"
              name="country"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomPhoneInput
              isInModal={true}
              value={phone}
              onChange={setPhone}
              label="Phone"
              name="phone"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomPhoneInput
              isInModal={true}
              value={phone2}
              onChange={setPhone2}
              label="Phone 2"
              name="phone2"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={fax}
              onChange={setFax}
              label="Fax"
              name="fax"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={email}
              onChange={setEmail}
              label="Email"
              name="email"
              type="email"
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ px: 2, pb: 2 }}>
        <Typography style={{ fontSize: '13px' }}>
          <strong>Date Created:</strong> {created}
          <br />
          <strong>Date Updated:</strong> {updated}
        </Typography>
        <FormControlLabel
          value="top"
          control={
            <Checkbox
              size="small"
              sx={{ '&.Mui-checked': { color: theme.vars.palette.seafoam.dark, [theme.getColorSchemeSelector('dark')]: { color: theme.vars.palette.seafoam.main } }, py: 0 }}
            />
          }
          label="Active"
          labelPlacement="end"
          id="isActiveUpdate"
          onChange={(e) => setIsActive(e.target.checked)}
          checked={isActive}
        />
        <Stack
          direction="row"
          spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="cancel"
            onClick={handleCancel}
            type="button">
            Cancel
          </Button>
          {!pending && (
            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{
                backgroundColor: theme.vars.palette.seafoam.dark,
                '&:hover': {
                  backgroundColor: theme.vars.palette.seafoam.darker
                }
              }}>
              Save Changes
            </Button>
          )}
          {pending && (
            <Button
              variant="contained"
              size="small"
              type="button"
              disabled>
              <CircularProgress
                sx={{ color: theme.vars.palette.cancel.light, mr: 0.5 }}
                size={18}
              />
              Saving...
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
export default UpdateCustomerForm
