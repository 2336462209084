import { useRef } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import { useSearchContext } from '../hooks/useSearchContext'
import { useColorScheme } from '@mui/material/styles'
import { useTheme } from '@mui/material'

const CustomSearch = ({ handleSetPage }) => {
  const inputRef = useRef()
  const { handleSearchChange, handleClearSearch, inputValue } = useSearchContext()
  const { mode } = useColorScheme()
  const theme = useTheme()

  const handleSearch = (e) => {
    const searchValue = e.target.value
    handleSearchChange(searchValue)
    handleSetPage(1)
  }

  return (
    <Stack
      direction="row"
      spacing={1.5}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
        <Box
          sx={{
            backgroundColor: theme.vars.palette.customBackground[mode],
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.vars.palette.customBorder[mode],
            borderRight: 'none',
            height: '100%',
            px: 1,
            display: 'grid',
            placeItems: 'center',
            cursor: 'pointer'
          }}
          onClick={() => inputRef.current.focus()}>
          <SearchIcon sx={{ fontSize: 24, color: 'grey' }} />
        </Box>
        <TextField
          fullWidth
          id="search"
          placeholder="Search..."
          variant="outlined"
          value={inputValue}
          size="small"
          aria-label="Search"
          onChange={handleSearch}
          inputRef={inputRef}
          InputProps={{
            sx: {
              fontSize: 14,
              '&:focus': {
                outline: '1px solid primary.main'
              }
            }
          }}
        />
      </Box>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleClearSearch}
        sx={{
          '&:hover': {
            color: '#fff',
            backgroundColor: theme.vars.palette.primary.dark,
            borderColor: theme.vars.palette.primary.dark
          }
        }}>
        Clear
      </Button>
    </Stack>
  )
}

export default CustomSearch
