import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const extUsersAdapter = createEntityAdapter({})

const initialState = extUsersAdapter.getInitialState()

export const extUsersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExtUser: builder.query({
      query: ({ id }) => ({
        url: `/distributor-user/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result, error, id) => [{ type: 'extUser', id }]
    }),
    getExtUsers: builder.query({
      query: () => ({
        url: '/external-users',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'extUser', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'extUser', id }))]
        } else return [{ type: 'extUser', id: 'LIST' }]
      }
    }),
    getPaginatedExtUsers: builder.query({
      query: ({ page = 1, limit = 20, sorting = [], searchTerm = '' }) => {
        return {
          url: '/external-users',
          params: { page, limit, columnSort: sorting.length > 0 ? sorting[0].id : '', columnType: sorting.length > 0 ? sorting[0].desc : '', searchTerm },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      },
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'extUser', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'extUser', id }))]
        } else return [{ type: 'extUser', id: 'LIST' }]
      }
    }),
    addNewExtUser: builder.mutation({
      query: (initialUserData) => ({
        url: '/external-users',
        method: 'POST',
        body: {
          ...initialUserData
        }
      }),
      invalidatesTags: ['extUser']
    }),
    updateExtUser: builder.mutation({
      query: (initialUserData) => ({
        url: '/external-users',
        method: 'PATCH',
        body: {
          ...initialUserData
        }
      }),
      invalidatesTags: ['extUser']
    }),
    deleteExtUser: builder.mutation({
      query: ({ id }) => ({
        url: '/external-users',
        method: 'DELETE',
        body: { id }
      }),
      invalidatesTags: ['extUser']
    })
  })
})

export const { useGetExtUserQuery, useGetExtUsersQuery, useGetPaginatedExtUsersQuery, useAddNewExtUserMutation, useUpdateExtUserMutation, useDeleteExtUserMutation } = extUsersApiSlice

// returns the query result object
export const selectExtUsersResult = extUsersApiSlice.endpoints.getExtUsers.select()

// creates memoized selector
const selectExtUsersData = createSelector(selectExtUsersResult, (extUserResult) => extUserResult.data) // normalized state object with ids & entities

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllExtUsers,
  selectById: selectExtUserById,
  selectIds: selectExtUserIds
  // Pass in a selector that returns the users slice of state
} = extUsersAdapter.getSelectors((state) => selectExtUsersData(state) ?? initialState)
