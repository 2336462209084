import { Stack, Tooltip, Button } from '@mui/material'

const CustomActionButtonGroup = ({ buttons, row }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={1}
      sx={{ pr: 1 }}>
      {buttons.map(
        ({ title, color, onClick, icon, visible }) =>
          visible && (
            <Tooltip
              key={title}
              title={title}>
              <Button
                color={color}
                aria-label={title}
                variant="contained"
                onClick={() => onClick(row.original)}
                sx={{
                  borderRadius: 0,
                  px: 0,
                  width: '25px',
                  height: '25px',
                  minWidth: '25px',
                  '& .MuiButton-startIcon': {
                    margin: 0,
                    '& svg': {
                      color: '#fff',
                      fontSize: '16px'
                    }
                  }
                }}
                startIcon={icon}
              />
            </Tooltip>
          )
      )}
    </Stack>
  )
}

export default CustomActionButtonGroup
