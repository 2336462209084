import { useContext } from 'react'
import { SearchContext } from '../features/context/SearchContext'

export const useSearchContext = () => {
  const context = useContext(SearchContext)

  if (!context) {
    throw Error('useSearchContext must be inside a SearchContextProvider')
  }

  return context
}
