import { useState } from 'react'
import { styled, useColorScheme } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

const StyledTextInput = styled('div')(({ theme, mode, isInModal }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: isInModal ? theme.vars.palette.inputBackground.light : '#fff',
    outline: '1px solid !important',
    outlineColor: '#ccc !important',
    fontSize: '1rem',
    width: '100%',
    padding: '0 .5rem',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    height: '31px',
    '&:focus': {
      boxShadow: `rgba(${theme.vars.palette.primary.mainChannel} / 0.25) 0 0 0 0.25rem`,
      [theme.getColorSchemeSelector('dark')]: {
        boxShadow: `rgba(${theme.vars.palette.primary.lightChannel} / 0.25) 0 0 0 0.25rem`
      }
    },
    [theme.getColorSchemeSelector('dark')]: {
      backgroundColor: isInModal ? theme.vars.palette.inputBackground.dark : theme.vars.palette.customBackground.dark,
      outlineColor: '#555 !important'
    }
  },
  '& label': {
    fontWeight: '600',
    color: theme.vars.palette.inputLabel[mode],
    fontSize: '.85rem',
    lineHeight: 1,
    marginBottom: 4,
    display: 'block',
    transition: theme.transitions.create(['color']),
    '&.focused': {
      color: theme.vars.palette.primary.main,
      [theme.getColorSchemeSelector('dark')]: {
        color: theme.vars.palette.primary.light
      }
    }
  }
}))

const CustomTextInput = ({ value, onChange, label, extraLabel, placeholder, disabled, type, required, isInModal, name, isUpperCase }) => {
  const [inputFocused, setInputFocused] = useState(false)
  const { mode } = useColorScheme()
  const theme = useTheme()
  return (
    <StyledTextInput
      mode={mode}
      isInModal={isInModal}>
      <FormControl
        variant="standard"
        sx={{ display: 'flex' }}>
        <label
          htmlFor={name}
          className={inputFocused ? 'focused' : ''}>
          {label}
          {extraLabel && (
            <Typography
              component="span"
              sx={{ color: theme.vars.palette.error.main, lineHeight: 1, fontSize: '.75rem', ml: 0.5 }}>
              {extraLabel}
            </Typography>
          )}
          {required && (
            <Typography
              component="span"
              sx={{ color: theme.vars.palette.error.main, lineHeight: 1, fontSize: '.85rem', ml: 0.125 }}>
              *
            </Typography>
          )}
        </label>
        <InputBase
          value={value}
          id={name}
          size="small"
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
          type={type ? type : 'text'}
          autoComplete="on"
          required={required}
          onFocus={() => setInputFocused(true)}
          onBlur={(e) => {
            setInputFocused(false)
            if (isUpperCase) {
              onChange(e.target.value.toUpperCase())
            }
          }}
          name={name}
          inputProps={{ autoComplete: 'on' }}
        />
      </FormControl>
    </StyledTextInput>
  )
}

export default CustomTextInput
