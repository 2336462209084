import { useCallback, useState } from 'react'
import UnitsHeader from './UnitsHeader'
import UnitsList from './UnitsList'

const UnitsPage = () => {
  const [page, setPage] = useState(1)

  const handleSetPage = useCallback(
    (newPage) => {
      setPage(newPage)
    },
    [setPage]
  )

  return (
    <>
      <UnitsHeader handleSetPage={handleSetPage} />
      <UnitsList
        page={page}
        handleSetPage={handleSetPage}
      />
    </>
  )
}

export default UnitsPage
