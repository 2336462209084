import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import { useModalContext } from '../../hooks/useModalContext'
import { toast } from 'react-toastify'
import { useAddNewEquipmentMutation } from '../equipments/equipmentsApiSlice'
import { useGetDistributorsQuery } from '../distributors/distributorsApiSlice'
import { useGetCustomersQuery } from '../customers/customersApiSlice'
import { useGetUnitsQuery } from '../units/unitsApiSlice'
import useAuth from '../../hooks/useAuth'
import CustomDatePicker from '../../components/CustomDatePicker'
import CustomTextInput from '../../components/CustomTextInput'
import CustomWindowedSelect from '../../components/CustomWindowedSelect'
import { useTheme } from '@mui/material'

const CreateEquipmentForm = () => {
  const theme = useTheme()
  const { handleCloseModal } = useModalContext()
  const [pending, setIsPending] = useState(false)

  const { data: distributors } = useGetDistributorsQuery('distributorsList')
  const { data: customers } = useGetCustomersQuery('customersList')
  const { data: units } = useGetUnitsQuery('unitsList')
  const { id: userId, name: userName } = useAuth()

  const [addNewEquipment] = useAddNewEquipmentMutation()

  const [serialNumber, setSerialNumber] = useState('')
  const [purchaseOrder, setPurchaseOrder] = useState('')
  const [shippingDate, setShippingDate] = useState('')

  const [distributor, setDistributor] = useState()
  const [customer, setCustomer] = useState()
  const [unit, setUnit] = useState()

  const [isDistributorValid, setIsDistributorValid] = useState(false)
  const [isUnitValid, setIsUnitValid] = useState(false)
  const distributorOptions =
    distributors &&
    distributors
      .filter((distributor) => distributor.isActive)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((distributor) => {
        return { value: distributor, label: distributor.name, customLabel: `${distributor.address} ${distributor.city}, ${distributor.state} ${distributor.zip}`, customLabel2: `${distributor?.isLicensee ? 'Licensee' : ''}` }
      })

  const customerOptions =
    customers &&
    customers
      .filter((customer) => customer.isActive)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((customer) => {
        return { value: customer, label: customer.name, customLabel: `${customer.address} ${customer.city}, ${customer.state} ${customer.zip}` }
      })

  const unitOptions =
    units &&
    units
      .filter((unit) => unit.isActive === true)
      .sort((a, b) => a.model.localeCompare(b.model))
      .map((unit) => {
        return { value: unit, label: unit.model }
      })

  const handleDistributorChange = (e) => {
    if (e === null) {
      setDistributor(null)
      setIsDistributorValid(false)
      return
    }
    setIsDistributorValid(true)
    setDistributor(e.value)
  }
  const handleCustomerChange = (e) => {
    if (e === null) {
      setCustomer(null)
      return
    }
    setCustomer(e.value)
  }

  const handleUnitChange = (e) => {
    if (e === null) {
      setUnit(null)
      setIsUnitValid(false)
      return
    }
    setIsUnitValid(true)
    setUnit(e.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsPending(true)
    const tempEquipment = {
      distributor: distributor._id,
      distributorName: distributor.name,
      purchaseOrder: purchaseOrder.toUpperCase(),
      serialNumber: serialNumber.toUpperCase(),
      shippingDate,
      unit: unit._id,
      unitModel: unit.model,
      user: userId,
      userName: userName,
      createdUser: userName
    }
    if (customer) {
      tempEquipment.customer = customer._id
      tempEquipment.customerName = customer.name
    }
    try {
      await addNewEquipment(tempEquipment).unwrap()
      toast.success(
        <>
          Serial number <strong>#{serialNumber}</strong> has been created.
        </>
      )
    } catch (error) {
      toast.warn(
        <>
          The serial number <strong>#{serialNumber}</strong> already exists. Please try a different number.
        </>
      )
    }
    handleCloseModal('createModal')
    setIsPending(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ px: 2, pb: 1, pt: 2 }}>
        <Grid
          container
          columnSpacing={2}
          alignItems="flex-end">
          <Grid
            xs={4}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={serialNumber}
              onChange={setSerialNumber}
              label="Serial Number"
              name="serialNumber"
              required
              isUpperCase={true}
            />
          </Grid>
          <Grid
            xs={4}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={purchaseOrder}
              onChange={setPurchaseOrder}
              label="Purchase Order Number"
              name="purchaseOrderNumber"
              required
              isUpperCase={true}
            />
          </Grid>
          <Grid
            xs={4}
            sx={{ mb: 1 }}>
            <CustomDatePicker
              isInModal={true}
              label="Shipping Date"
              value={shippingDate}
              onChange={setShippingDate}
              required
            />
          </Grid>
          <Grid
            xs={12}
            sx={{ mb: 1 }}>
            <CustomWindowedSelect
              isInModal={true}
              options={distributorOptions}
              onChange={handleDistributorChange}
              conditonalLabel={'You must select a Distributor/Licensee/OEM.'}
              titleLabel="Distributor"
              required
            />
          </Grid>
          <Grid
            xs={12}
            sx={{ mb: 1 }}>
            <CustomWindowedSelect
              isInModal={true}
              options={customerOptions}
              onChange={handleCustomerChange}
              titleLabel="Customer"
            />
          </Grid>
          <Grid
            xs={12}
            sx={{ mb: 1 }}>
            <CustomWindowedSelect
              isInModal={true}
              options={unitOptions}
              onChange={handleUnitChange}
              conditonalLabel={'You must select a Unit.'}
              titleLabel="Unit"
              required
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{ px: 2, pb: 2 }}>
        <Stack
          direction="row"
          spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="cancel"
            onClick={() => handleCloseModal('createModal')}
            type="button">
            Cancel
          </Button>
          {!pending && (
            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{
                backgroundColor: theme.vars.palette.slate.dark,
                '&:hover:not([disabled])': {
                  backgroundColor: theme.vars.palette.slate.darker
                },
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all'
                }
              }}
              disabled={!(isDistributorValid && isUnitValid && serialNumber && purchaseOrder && shippingDate)}>
              Add Equipment
            </Button>
          )}
          {pending && (
            <Button
              variant="contained"
              size="small"
              type="button"
              disabled>
              <CircularProgress
                sx={{ color: theme.vars.palette.cancel.light, mr: 0.5 }}
                size={18}
              />
              Adding...
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}

export default CreateEquipmentForm
