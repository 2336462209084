import CustomSearch from '../../components/CustomSearch'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const EquipmentsHeader = ({ handleSetPage }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ mb: 1 }}>
        <Typography variant="h1">Equipment</Typography>
        <CustomSearch handleSetPage={handleSetPage} />
      </Stack>
    </>
  )
}

export default EquipmentsHeader
