import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import { useAddNewCustomerMutation } from '../customers/customersApiSlice'
import { useModalContext } from '../../hooks/useModalContext'
import { toast } from 'react-toastify'
import CustomTextInput from '../../components/CustomTextInput'
import CustomPhoneInput from '../../components/CustomPhoneInput'
import { useTheme } from '@mui/material'

const CreateCustomerForm = () => {
  const { handleCloseModal } = useModalContext()
  const [addNewCustomer] = useAddNewCustomerMutation()
  const theme = useTheme()

  const [pending, setIsPending] = useState(false)
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [phone2, setPhone2] = useState('')
  const [fax, setFax] = useState('')
  const [email, setEmail] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsPending(true)

    try {
      await addNewCustomer({ name, address, address2, city, state, zip, country, phone, phone2, fax, email }).unwrap()
      toast.success(
        <>
          Customer <strong>{name}</strong> has been created.
        </>
      )
    } catch (error) {
      toast.error(
        <>
          Customer <strong>{name}</strong> was NOT created.
        </>
      )
    }

    handleCloseModal('createModal')
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ px: 2, pb: 1, pt: 2 }}>
        <Grid
          container
          columnSpacing={2}>
          <Grid
            sm={12}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={name}
              onChange={setName}
              label="Name"
              name="name"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={address}
              onChange={setAddress}
              label="Address"
              name="address"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={address2}
              onChange={setAddress2}
              label="Address 2"
              name="address2"
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={city}
              onChange={setCity}
              label="City"
              name="city"
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={state}
              onChange={setState}
              label="State"
              name="state"
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={zip}
              onChange={setZip}
              label="Zip"
              name="zip"
            />
          </Grid>
          <Grid
            sm={3}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={country}
              onChange={setCountry}
              label="Country"
              name="country"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomPhoneInput
              isInModal={true}
              value={phone}
              onChange={setPhone}
              label="Phone"
              name="phone"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomPhoneInput
              isInModal={true}
              value={phone2}
              onChange={setPhone2}
              label="Phone 2"
              name="phone2"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={fax}
              onChange={setFax}
              label="Fax"
              name="fax"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={email}
              onChange={setEmail}
              label="Email"
              name="email"
              type="email"
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{ px: 2, pb: 2 }}>
        <Stack
          direction="row"
          spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="cancel"
            onClick={() => handleCloseModal('createModal')}
            type="button">
            Cancel
          </Button>
          {!pending && (
            <Button
              variant="contained"
              size="small"
              type="submit"
              disabled={name === ''}
              sx={{
                backgroundColor: theme.vars.palette.seafoam.dark,
                '&:hover:not([disabled])': {
                  backgroundColor: theme.vars.palette.seafoam.darker
                },
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all'
                }
              }}>
              Add Customer
            </Button>
          )}
          {pending && (
            <Button
              variant="contained"
              size="small"
              type="button"
              disabled>
              <CircularProgress
                sx={{ color: 'cancel.light', mr: 0.5 }}
                size={18}
              />
              Adding...
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
export default CreateCustomerForm
