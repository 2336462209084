import { memo, useCallback, useMemo, useState } from 'react'
import { useGetPaginatedLogsQuery } from './logsApiSlice'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import useTitle from '../../hooks/useTitle'
import BeatLoader from 'react-spinners/BeatLoader'
import CustomTable from '../../components/CustomTable'
import CustomTableFooter from '../../components/CustomTableFooter'
import usePageTitle from '../../hooks/usePageTitle'
import Tooltip from '@mui/material/Tooltip'
import { useSearchContext } from '../../hooks/useSearchContext'
import { useTheme } from '@mui/material'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const LogsList = memo(({ page, handleSetPage }) => {
  useTitle('Logs | BILL Dashboard')
  usePageTitle('log')
  const theme = useTheme()

  const { searchTerm } = useSearchContext()

  const [sorting, setSorting] = useState([{ id: 'createdAt', desc: true }])
  const [limit, setLimit] = useState(20)

  const headerColor = theme.palette.mode === 'dark' ? 'cancel' : 'black'
  const beatLoaderColor = theme.palette.mode === 'dark' ? theme.vars.palette.cancel.main : theme.vars.palette.black.main

  const handleLimitChange = useCallback(
    (e) => {
      handleSetPage(1)
      setLimit(+e.target.value)
    },
    [handleSetPage, setLimit]
  )

  const handleSetSorting = useCallback(
    (newSorting) => {
      handleSetPage(1)
      setSorting(newSorting)
    },
    [handleSetPage, setSorting]
  )

  const {
    data: logs,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetPaginatedLogsQuery({ page, limit, sorting, searchTerm }, 'logsList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true
  })

  const columns = useMemo(
    () => [
      {
        accessorKey: 'createdAt',
        id: 'createdAt',
        header: 'Created At',
        size: 125,
        minSize: 75,
        maxSize: 125,
        grow: 1,
        Cell: ({ row }) => (row?.original?.createdAt ? dayjs(row?.original?.createdAt).format('MM/DD/YYYY hh:mm A z') : '')
      },
      {
        accessorKey: 'action',
        id: 'action',
        header: 'Action',
        size: 75,
        minSize: 50,
        maxSize: 100,
        grow: 1,
        Cell: ({ row }) => {
          return <span style={{ textTransform: 'capitalize' }}>{row?.original?.action}</span>
        }
      },
      {
        accessorKey: 'entityType',
        id: 'entityType',
        header: 'Entity Type',
        size: 75,
        minSize: 50,
        maxSize: 100,
        grow: 1
      },
      {
        accessorKey: 'entityName',
        id: 'entityName',
        header: 'Entity Name',
        size: 100,
        minSize: 75,
        maxSize: 125,
        grow: 1
      },
      {
        accessorKey: 'userName',
        id: 'userName',
        header: 'User Name',
        size: 100,
        minSize: 75,
        maxSize: 125,
        grow: 1
      },
      {
        accessorKey: 'message',
        id: 'message',
        header: 'Message',
        size: 200,
        minSize: 150,
        maxSize: 250,
        grow: 1,
        Cell: ({ row }) => {
          const message = row.original.message
          const displayMessage = message.substring(0, 60)
          const hasToolTip = message.length > 60

          return hasToolTip ? (
            <Tooltip
              title={message}
              enterDelay={250}>
              <span>{displayMessage}...</span>
            </Tooltip>
          ) : (
            <span>{displayMessage}</span>
          )
        }
      },
      {
        accessorKey: 'userAgent',
        id: 'userAgent',
        header: 'User Agent',
        size: 200,
        minSize: 150,
        maxSize: 250,
        grow: 1,
        Cell: ({ row }) => (
          <Tooltip
            title={row.original.userAgent}
            enterDelay={250}>
            <span>{row.original.userAgent.substring(0, 60)}</span>
          </Tooltip>
        )
      },
      {
        accessorKey: 'ipAddress',
        id: 'ipAddress',
        header: 'IP Address',
        size: 60,
        minSize: 60,
        maxSize: 70,
        grow: 1
      }
    ],
    []
  )

  if (isLoading)
    return (
      <BeatLoader
        color={beatLoaderColor}
        size={20}
      />
    )

  if (isError) return <p className="errmsg">{error?.data?.message}</p>

  if (isSuccess) {
    const { docs } = logs

    return (
      <>
        <CustomTable
          columns={columns}
          docs={docs}
          isError={isError}
          sorting={sorting}
          handleSetSorting={handleSetSorting}
          color={headerColor}
          applyIsActiveStyle={false}
        />
        <CustomTableFooter
          page={page}
          handleSetPage={handleSetPage}
          data={logs}
          limit={limit}
          handleLimitChange={handleLimitChange}
        />
      </>
    )
  }
})

export default LogsList
