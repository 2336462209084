import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const usersAdapter = createEntityAdapter({})

const initialState = usersAdapter.getInitialState()

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: ({ id }) => ({
        url: `/users/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result, error, id) => [{ type: 'User', id }]
    }),
    getUsers: builder.query({
      query: () => ({
        url: '/users',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'User', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'User', id }))]
        } else return [{ type: 'User', id: 'LIST' }]
      }
    }),
    getPaginatedUsers: builder.query({
      query: ({ page = 1, limit = 20, sorting = [], searchTerm = '' }) => {
        return {
          url: '/users',
          params: { page, limit, columnSort: sorting.length > 0 ? sorting[0].id : '', columnType: sorting.length > 0 ? sorting[0].desc : '', searchTerm },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      },
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'User', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'User', id }))]
        } else return [{ type: 'User', id: 'LIST' }]
      }
    }),
    addNewUser: builder.mutation({
      query: (initialUserData) => ({
        url: '/users',
        method: 'POST',
        body: {
          ...initialUserData
        }
      }),
      invalidatesTags: ['User']
    }),
    updateUser: builder.mutation({
      query: (initialUserData) => ({
        url: '/users',
        method: 'PATCH',
        body: {
          ...initialUserData
        }
      }),
      invalidatesTags: ['User']
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: '/users',
        method: 'DELETE',
        body: { id }
      }),
      invalidatesTags: ['User']
    })
  })
})

export const { useGetUserQuery, useGetUsersQuery, useGetPaginatedUsersQuery, useAddNewUserMutation, useUpdateUserMutation, useDeleteUserMutation } = usersApiSlice

// returns the query result object
export const selectUsersResult = usersApiSlice.endpoints.getUsers.select()

// creates memoized selector
const selectUsersData = createSelector(selectUsersResult, (usersResult) => usersResult.data) // normalized state object with ids & entities

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds
  // Pass in a selector that returns the users slice of state
} = usersAdapter.getSelectors((state) => selectUsersData(state) ?? initialState)
