import { useState } from 'react'
import { useLazyGetFilteredEquipmentsQuery } from '../equipments/equipmentsApiSlice'
import { useGetDistributorsQuery } from '../distributors/distributorsApiSlice'
import CustomWindowedSelect from '../../components/CustomWindowedSelect'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import ReportsList from './ReportsList'
import BeatLoader from 'react-spinners/BeatLoader'
import CustomMultiDatePicker from '../../components/CustomMultiDatePicker'
import CustomRadioGroup from '../../components/CustomRadioGroup'

const ReportDistributor = () => {
  const reportType = 'Distributors'
  const [shippingDates, setShippingDates] = useState([])
  const [shippingStartDate, shippingEndDate] = shippingDates
  const [registrationDates, setRegistrationDates] = useState([])
  const [registrationStartDate, registrationEndDate] = registrationDates
  const [distributorName, setDistributorName] = useState('')
  const [distributorId, setDistributorId] = useState('')
  const [showTable, setShowTable] = useState(false)

  const { data: distributors } = useGetDistributorsQuery()

  const [getFilteredEquipment, { data: equipments, isLoading: isLoadingEquipments, isFetching: isFetchingEquipments, isError: isErrorEquipments, error: errorEquipments }] = useLazyGetFilteredEquipmentsQuery()

  // Omitted distributors checkbox
  const [excludeOmittedDistributors, setExcludeOmittedDistributors] = useState(false)
  const [excludeOmittedDistributorsIsDisabled, setExcludeOmittedDistributorsIsDisabled] = useState(false)

  // Radio buttons
  const [distributorIsRecycled, setDistributorIsRecycled] = useState('')
  const [distributorIsRegistered, setDistributorIsRegistered] = useState('')
  const [distributorIsExpired, setDistributorIsExpired] = useState('')
  const [resetKey, setResetKey] = useState(0)

  const columns = [
    {
      field: 'createdUser',
      headerName: 'Created By User',
      sortable: false,
      resizable: false
    },
    {
      field: 'distributorName',
      headerName: 'Distributor',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false,
      hideable: false
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      valueGetter: (params) => (params.row.customer ? params.row.customer.name : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerAddress',
      headerName: 'Customer Address',
      valueGetter: (params) => (params.row.customer ? params.row.customer.address : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerAddress2',
      headerName: 'Customer Address 2',
      valueGetter: (params) => (params.row.customer ? params.row.customer.address2 : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerCity',
      headerName: 'Customer City',
      valueGetter: (params) => (params.row.customer ? params.row.customer.city : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerState',
      headerName: 'Customer State',
      valueGetter: (params) => (params.row.customer ? params.row.customer.state : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerZip',
      headerName: 'Customer Zip',
      valueGetter: (params) => {
        const zip = params?.row?.customer?.zip
        return zip ? zip.toString().padStart(5, '0') : ''
      },
      sortable: false,
      resizable: false
    },
    {
      field: 'customerCountry',
      headerName: 'Customer Country',
      valueGetter: (params) => (params.row.customer ? params.row.customer.country : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerPhone',
      headerName: 'Customer Phone',
      valueGetter: (params) => (params.row.customer ? params.row.customer.phone : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerPhone2',
      headerName: 'Customer Phone 2',
      valueGetter: (params) => (params.row.customer ? params.row.customer.phone2 : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerFax',
      headerName: 'Customer Fax',
      valueGetter: (params) => (params.row.customer ? params.row.customer.fax : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerEmail',
      headerName: 'Customer Email',
      valueGetter: (params) => (params.row.customer ? params.row.customer.email : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'customerType',
      headerName: 'Customer Type',
      sortable: false,
      resizable: false
    },
    {
      field: 'expirationDate',
      headerName: 'Expiration Date',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.expirationDate ? new Date(params.row.expirationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'installationDate',
      headerName: 'Installation Date',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.installationDate ? new Date(params.row.installationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'installationType',
      headerName: 'Installation Type',
      sortable: false,
      resizable: false
    },
    {
      field: 'purchaseOrder',
      headerName: 'Purchase Order #',
      sortable: false,
      resizable: false
    },
    {
      field: 'preRegistrantAward',
      headerName: 'Pre-Registrant Award',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'preRegistrantCardOrdered',
      headerName: 'Pre-Registrant Card Ordered',
      valueGetter: (params) => (params.row.preRegistrantCardOrdered ? new Date(params.row.preRegistrantCardOrdered).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'preRegistrantFirstName',
      headerName: 'Pre-Registrant First Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'preRegistrantLastName',
      headerName: 'Pre-Registrant Last Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'preRegistrantEmail',
      headerName: 'Pre-Registrant Email',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'preRegistrantMoneyLoaded',
      headerName: 'Pre-Registrant Money Loaded',
      valueGetter: (params) => (params.row.preRegistrantMoneyLoaded ? new Date(params.row.preRegistrantMoneyLoaded).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'preRegistrationDate',
      headerName: 'Pre-Registration Date',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      valueGetter: (params) => (params.row.preRegistrationDate ? new Date(params.row.preRegistrationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      resizable: false
    },
    {
      field: 'registrantAward',
      headerName: 'Registrant Award',
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantCardOrdered',
      headerName: 'Registrant Card Ordered',
      valueGetter: (params) => (params.row.registrantCardOrdered ? new Date(params.row.registrantCardOrdered).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantEmail',
      headerName: 'Registrant Email',
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantFirstName',
      headerName: 'Registrant First Name',
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantLastName',
      headerName: 'Registrant Last Name',
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantMoneyLoaded',
      headerName: 'Registrant Money Loaded',
      valueGetter: (params) => (params.row.registrantMoneyLoaded ? new Date(params.row.registrantMoneyLoaded).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'registrationDate',
      headerName: 'Registration Date',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.registrationDate ? new Date(params.row.registrationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'rmaIssueDate',
      headerName: 'RMA Issue Date',
      valueGetter: (params) => (params.row.rmaIssueDate ? new Date(params.row.rmaIssueDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'rmaNumber',
      headerName: 'RMA Number',
      sortable: false,
      resizable: false
    },
    {
      field: 'rmaReturnedDate',
      headerName: 'RMA Returned Date',
      valueGetter: (params) => (params.row.rmaReturnedDate ? new Date(params.row.rmaReturnedDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'unitModel',
      headerName: 'Unit Model',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false
    },
    {
      field: 'compressorHorsepower',
      headerName: 'Compressor Horsepower',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false
    },
    {
      field: 'serialNumber',
      headerName: 'Serial #',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false
    },
    {
      field: 'serialNumberOld',
      headerName: 'Old Serial Number',
      sortable: false,
      resizable: false
    },
    {
      field: 'isRecycled',
      headerName: 'Recycled?',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.isRecycled === true ? 'yes' : 'no'),
      sortable: false,
      resizable: false
    },
    {
      field: 'isRegistered',
      headerName: 'Registered?',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row.isRegistered === true ? 'yes' : 'no'),
      sortable: false,
      resizable: false
    },
    {
      field: 'salesFirstName',
      headerName: 'Sales First Name',
      sortable: false,
      resizable: false
    },
    {
      field: 'salesLastName',
      headerName: 'Sales Last Name',
      sortable: false,
      resizable: false
    },
    {
      field: 'salesEmail',
      headerName: 'Sales Email',
      sortable: false,
      resizable: false
    },
    {
      field: 'salesAward',
      headerName: 'Sales Award',
      sortable: false,
      resizable: false
    },
    {
      field: 'salesCardOrdered',
      headerName: 'Sales Card Ordered',
      valueGetter: (params) => (params.row.salesCardOrdered ? new Date(params.row.salesCardOrdered).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'salesMoneyLoaded',
      headerName: 'Sales Money Loaded',
      sortable: false,
      resizable: false
    },
    {
      field: 'shippingDate',
      headerName: 'Shipping Date',
      valueGetter: (params) => (params.row.shippingDate ? new Date(params.row.shippingDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'updatedUser',
      headerName: 'Updated User',
      sortable: false,
      resizable: false
    },
    {
      field: 'userName',
      headerName: 'User Name',
      sortable: false,
      resizable: false
    }
  ]

  const handleDistributorNameChange = (e) => {
    if (e === null) {
      setDistributorName('')
      setDistributorId('')
      setExcludeOmittedDistributorsIsDisabled(false)
      return
    }
    setDistributorName(e.value.name)
    setDistributorId(e.value._id)
    setExcludeOmittedDistributors(false)
    setExcludeOmittedDistributorsIsDisabled(true)
  }

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setExcludeOmittedDistributors(true)
      return
    }
    setExcludeOmittedDistributors(false)
  }

  const handleFilterSubmit = async (e) => {
    e.preventDefault()
    const formatDate = (dateString) => {
      const date = new Date(dateString)
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const year = date.getFullYear()
      return `${month}/${day}/${year}`
    }

    const formattedShippingStartDate = shippingDates.length ? formatDate(shippingStartDate) : ''
    const formattedShippingEndDate = shippingDates.length ? formatDate(shippingEndDate) : ''
    const formattedRegistrationStartDate = registrationDates.length ? formatDate(registrationStartDate) : ''
    const formattedRegistrationEndDate = registrationDates.length ? formatDate(registrationEndDate) : ''

    const equipmentFilter = {
      isRecycled: distributorIsRecycled,
      isRegistered: distributorIsRegistered,
      isExpired: distributorIsExpired,
      distributorName,
      distributorId,
      shippingStartDate: formattedShippingStartDate,
      shippingEndDate: formattedShippingEndDate,
      registrationStartDate: formattedRegistrationStartDate,
      registrationEndDate: formattedRegistrationEndDate,
      excludeOmittedDistributors,
      reportType
    }

    await getFilteredEquipment(equipmentFilter)
    setShowTable(true)
  }

  const handleReset = (e) => {
    e.preventDefault()
    setResetKey((prevKey) => prevKey + 1)
    setDistributorName('')
    setDistributorId('')
    setDistributorIsRecycled('')
    setDistributorIsRegistered('')
    setDistributorIsExpired('')
    setShippingDates([])
    setRegistrationDates([])
    setExcludeOmittedDistributors(false)
    setExcludeOmittedDistributorsIsDisabled(false)
    setShowTable(false)
  }

  const distributorOptions =
    distributors &&
    [...distributors]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((distributor) => {
        return { value: distributor, label: distributor.name, customLabel: `${distributor.address} ${distributor.city}, ${distributor.state} ${distributor.zip}` }
      })

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        marginBottom={1}
        alignItems="flex-end">
        <Grid
          xs={12}
          lg={3}>
          <CustomWindowedSelect
            key={resetKey}
            options={distributorOptions}
            onChange={handleDistributorNameChange}
            titleLabel={
              <>
                Distributors - <small>leave blank for all distributors</small>
              </>
            }
            defaultValue={''}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg="auto">
          <CustomRadioGroup
            id="distributor-recycled-group"
            label="Recycled?"
            value={distributorIsRecycled}
            onChange={(e) => setDistributorIsRecycled(e.target.value)}
            options={[
              { value: 'true', label: 'Recycled' },
              { value: 'false', label: 'Not recycled' },
              { value: '', label: 'None' }
            ]}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg="auto">
          <CustomRadioGroup
            id="distributor-registered-group"
            label="Registered?"
            value={distributorIsRegistered}
            onChange={(e) => setDistributorIsRegistered(e.target.value)}
            options={[
              { value: 'true', label: 'Registered' },
              { value: 'false', label: 'Not registered' },
              { value: '', label: 'None' }
            ]}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg="auto">
          <CustomRadioGroup
            id="distributor-expired-group"
            label="Expired?"
            value={distributorIsExpired}
            onChange={(e) => setDistributorIsExpired(e.target.value)}
            options={[
              { value: 'true', label: 'Expired' },
              { value: 'false', label: 'Not expired' },
              { value: '', label: 'None' }
            ]}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={3}
          xl={2}
          sx={{ position: 'relative' }}>
          <FormControlLabel
            sx={{ fontSize: '14px', userSelect: 'none' }}
            control={
              <Checkbox
                size="small"
                checked={excludeOmittedDistributors}
              />
            }
            label="Exclude omitted distributors"
            onChange={handleCheckboxChange}
            disabled={excludeOmittedDistributorsIsDisabled}
          />
          <CustomMultiDatePicker
            label="Shipping Date"
            startDate={shippingStartDate}
            endDate={shippingEndDate}
            onChange={(update) => setShippingDates(update)}
          />
        </Grid>
        <Grid
          xs={12}
          md={4}
          lg={3}
          xl={2}
          sx={{ position: 'relative' }}>
          <CustomMultiDatePicker
            label="Registration Date"
            startDate={registrationStartDate}
            endDate={registrationEndDate}
            onChange={(update) => setRegistrationDates(update)}
          />
        </Grid>
        <Grid xs="auto">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            onClick={handleFilterSubmit}>
            Submit
          </Button>
          <Button
            variant="contained"
            color="user"
            onClick={handleReset}
            sx={{ mr: 1 }}>
            Reset
          </Button>
        </Grid>
      </Grid>

      {(isLoadingEquipments || isFetchingEquipments) && (
        <BeatLoader
          color={'#333'}
          size={20}
          style={{ margin: '8px' }}
        />
      )}

      {!isLoadingEquipments && !isFetchingEquipments && (
        <>
          {isErrorEquipments && <Typography sx={{ py: 2 }}>{errorEquipments.data.message}</Typography>}

          {equipments && equipments.message && <Typography sx={{ py: 2 }}>{equipments.message}</Typography>}

          {equipments && !equipments.message && equipments.length === 0 && <Typography sx>No results</Typography>}

          {equipments && !equipments.message && equipments.length > 0 && showTable && (
            <ReportsList
              equipments={equipments}
              columns={columns}
              reportType={reportType}
            />
          )}
        </>
      )}
    </>
  )
}

export default ReportDistributor
