import { useCallback, useState } from 'react'
import CustomersHeader from './CustomersHeader'
import CustomersList from './CustomersList'

const CustomersPage = () => {
  const [page, setPage] = useState(1)

  const handleSetPage = useCallback(
    (newPage) => {
      setPage(newPage)
    },
    [setPage]
  )

  return (
    <>
      <CustomersHeader handleSetPage={handleSetPage} />
      <CustomersList
        page={page}
        handleSetPage={handleSetPage}
      />
    </>
  )
}

export default CustomersPage
