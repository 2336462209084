import { useState } from 'react'
import { styled, useColorScheme } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useTheme } from '@mui/material'

const StyledRadioGroup = styled('div')(({ theme, mode }) => ({
  '& label': {
    fontWeight: '700',
    color: theme.vars.palette.inputLabel[mode],
    fontSize: '.85rem',
    lineHeight: 1,
    display: 'block',
    transition: theme.transitions.create(['color']),
    '&.focused': {
      color: theme.vars.palette.primary.main,
      [theme.getColorSchemeSelector('dark')]: {
        color: theme.vars.palette.primary.light
      }
    }
  }
}))

const CustomRadioGroup = ({ id, label, value, onChange, options, isInModal }) => {
  const [inputFocused, setInputFocused] = useState(false)
  const { mode } = useColorScheme()
  const theme = useTheme()
  return (
    <StyledRadioGroup
      mode={mode}
      isInModal={isInModal}>
      <FormControl>
        <RadioGroup
          aria-labelledby={id}
          name={id}
          value={value}
          onChange={onChange}>
          <label
            className={inputFocused ? 'focused' : ''}
            style={{ userSelect: 'none', marginBottom: 4 }}>
            {label}
          </label>
          {options.map((option, index) => {
            const uniqueId = `${id}-${index}`
            return (
              <FormControlLabel
                id={uniqueId}
                key={option.value}
                value={option.value}
                control={<Radio sx={{ color: mode === 'dark' ? 'white' : 'gray', p: 0.5, '&:checked': { color: theme.vars.palette.error.main } }} />}
                label={option.label}
                disabled={option.disabled}
                sx={{ userSelect: 'none' }}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    </StyledRadioGroup>
  )
}

export default CustomRadioGroup
