import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useModalContext } from '../../hooks/useModalContext'
import { useTheme } from '@mui/material'

const DeleteModal = () => {
  const { handleOpenModal, handleCloseModal, selectedRow, pageTitle, openModals } = useModalContext()
  const theme = useTheme()

  const handleYesClick = () => {
    handleCloseModal('deleteModal')
    handleOpenModal('confirmDeleteModal')
  }

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    handleCloseModal('deleteModal')
  }

  const getDeleteMessage = () => {
    if (pageTitle === 'unit') {
      return `Are you sure you want to delete model ${selectedRow.model}?`
    } else if (pageTitle === 'equipment' || pageTitle === 'recycled') {
      return `Are you sure you want to delete serial number ${selectedRow.serialNumber}?`
    } else if (pageTitle === 'customer') {
      return `Are you sure you want to delete customer ${selectedRow.name}?`
    } else if (pageTitle === 'externaluser' || pageTitle === 'user') {
      return `Are you sure you want to delete user ${selectedRow.name}?`
    } else if (pageTitle === 'distributor') {
      return `Are you sure you want to delete distributor ${selectedRow.name}?`
    } else {
      return `Are you sure you want to delete ${selectedRow.name}?`
    }
  }

  return (
    <Modal
      open={openModals.deleteModal}
      onClose={handleClose}
      closeAfterTransition>
      <Fade in={openModals.deleteModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, boxShadow: 24, backgroundColor: theme.vars.palette.customBackground[theme.palette.mode] }}>
          <Stack
            direction="row"
            sx={{ justifyContent: 'space-between', alignItems: 'center', backgroundColor: theme.vars.palette.error.main, py: 0.5, pl: 2, pr: 1 }}>
            <Typography
              color="white"
              fontWeight="500"
              fontSize={18}>
              Delete Record
            </Typography>
            <IconButton
              aria-label="Close"
              onClick={() => handleCloseModal('deleteModal')}
              sx={{
                color: 'white'
              }}>
              <CloseIcon fontSize="20px" />
            </IconButton>
          </Stack>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mb: 3 }}>
              {getDeleteMessage()} <strong>You can't undo this change.</strong>
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{ justifyContent: 'center' }}>
              <Button
                size="small"
                variant="contained"
                color="cancel"
                onClick={handleClose}
                type="button"
                sx={{ width: '40px', minWidth: '40px', height: '31px' }}>
                No
              </Button>
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={handleYesClick}
                type="button"
                sx={{ width: '40px', minWidth: '40px', height: '31px' }}>
                Yes
              </Button>
            </Stack>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default DeleteModal
