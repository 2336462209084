import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { toast } from 'react-toastify'
import { useModalContext } from '../../hooks/useModalContext'
import { useDeleteEquipmentMutation } from '../equipments/equipmentsApiSlice'
import { useDeleteDistributorMutation } from '../distributors/distributorsApiSlice'
import { useDeleteCustomerMutation } from '../customers/customersApiSlice'
import { useDeleteUserMutation } from '../users/usersApiSlice'
import { useDeleteExtUserMutation } from '../users/extUsersApiSlice'
import { useDeleteUnitMutation } from '../units/unitsApiSlice'
import { useTheme } from '@mui/material'

const ConfirmDeleteModal = () => {
  const { selectedRow, pageTitle, openModals, handleCloseModal } = useModalContext()
  const theme = useTheme()

  const [deleteEquipment] = useDeleteEquipmentMutation()
  const [deleteDistributor] = useDeleteDistributorMutation()
  const [deleteCustomer] = useDeleteCustomerMutation()
  const [deleteUnit] = useDeleteUnitMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [deleteExtUser] = useDeleteExtUserMutation()

  const handleDelete = async () => {
    try {
      switch (pageTitle) {
        case 'equipment':
        case 'recycled':
          try {
            await deleteEquipment({ id: selectedRow.id })
            toast.success(
              <>
                {pageTitle === 'recycled' ? 'recycled' : ''} Equipment <strong>{selectedRow.serialNumber}</strong> has been deleted.
              </>
            )
          } catch (eror) {
            toast.error(<>Record was not deleted.</>)
          }
          break
        case 'customer':
          await deleteCustomer({ id: selectedRow.id })
          toast.success(
            <>
              Customer <strong>{selectedRow.name}</strong> has been deleted.
            </>
          )
          break
        case 'externaluser':
          await deleteExtUser({ id: selectedRow.id })
          toast.success(
            <>
              External user <strong>{selectedRow.name}</strong> has been deleted.
            </>
          )
          break
        case 'user':
          await deleteUser({ id: selectedRow.id })
          toast.success(
            <>
              User <strong>{selectedRow.name}</strong> has been deleted.
            </>
          )
          break
        case 'distributor':
          await deleteDistributor({ id: selectedRow.id })
          toast.success(
            <>
              Distributor <strong>{selectedRow.name}</strong> has been deleted.
            </>
          )
          break
        case 'unit':
          await deleteUnit({ id: selectedRow.id })
          toast.success(
            <>
              Unit <strong>{selectedRow.model}</strong> has been deleted.
            </>
          )
          break
        default:
          toast.error(<>Record was not deleted.</>)
          break
      }
    } catch (error) {
      toast.error(<>Record was not deleted.</>)
    }
    handleCloseModal('confirmDeleteModal')
  }

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    handleCloseModal('confirmDeleteModal')
  }

  return (
    <Modal
      open={openModals.confirmDeleteModal}
      onClose={handleClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, boxShadow: 24, backgroundColor: theme.vars.palette.customBackground[theme.palette.mode] }}>
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', alignItems: 'center', backgroundColor: theme.vars.palette.error.main, py: 0.5, pl: 2, pr: 1 }}>
          <Typography
            color="white"
            fontWeight="500"
            fontSize={18}>
            Confirm Delete
          </Typography>
          <IconButton
            aria-label="Close"
            onClick={() => handleCloseModal('confirmDeleteModal')}
            sx={{
              color: 'white'
            }}>
            <CloseIcon fontSize="20px" />
          </IconButton>
        </Stack>
        <Box sx={{ p: 2 }}>
          <Typography sx={{ mb: 3 }}>
            Are you sure you want to delete this record? An alternative would be to deactivate it. <strong>You still can't undo this change.</strong>
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: 'center' }}>
            <Button
              size="small"
              variant="contained"
              color="cancel"
              onClick={handleClose}
              type="button"
              sx={{ width: '40px', minWidth: '40px', height: '31px' }}>
              No
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={handleDelete}
              type="button"
              sx={{ width: '40px', minWidth: '40px', height: '31px' }}>
              Yes
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}
export default ConfirmDeleteModal
