import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../features/auth/authSlice'
import jwtDecode from 'jwt-decode'

const useAuth = () => {
  const token = useSelector(selectCurrentToken)

  if (token) {
    const decoded = jwtDecode(token)
    const { id, name, email, role, distributorName, distributor } = decoded.UserInfo
    return { id, name, email, role, distributorName, distributor }
  }

  return { id: '', name: '', email: '', role: '', distributorName: '', distributor: '' }
}
export default useAuth
