import { apiSlice } from '../../app/api/apiSlice'

export const calcApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAwardValue: builder.query({
      query: ({ unitModel, isSalesFirstName, sameName }) => ({
        url: '/calc/award',
        method: 'POST',
        params: {
          unitModel,
          isSalesFirstName,
          sameName
        },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      })
    }),
    getExpirationDate: builder.query({
      query: ({ unitModel, compressorHorsepower, installationDate }) => {
        return {
          url: '/calc/expiration',
          method: 'POST',
          params: {
            unitModel,
            compressorHorsepower,
            installationDate
          },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      }
    })
  })
})

export const { useLazyGetAwardValueQuery, useLazyGetExpirationDateQuery } = calcApiSlice
