import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useModalContext } from '../../hooks/useModalContext'
import { useTheme } from '@mui/material'

const UpdateModal = ({ children }) => {
  const theme = useTheme()
  const { handleCloseModal, pageTitle, openModals } = useModalContext()

  const getBackgroundColor = () => {
    switch (pageTitle) {
      case 'customer':
        return theme.vars.palette.seafoam.dark
      case 'distributor':
        return theme.vars.palette.maroon.dark
      case 'user':
      case 'externaluser':
        return theme.vars.palette.black.dark
      default:
        return theme.vars.palette.slate.dark
    }
  }

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    handleCloseModal('updateModal')
  }

  return (
    <Modal
      open={openModals.updateModal}
      onClose={handleClose}
      closeAfterTransition>
      <Fade in={openModals.updateModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 800, boxShadow: 24, backgroundColor: theme.vars.palette.customBackground[theme.palette.mode] }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ backgroundColor: getBackgroundColor(), py: 1, pl: 2, pr: 1, color: 'white' }}>
            <Typography
              component="h4"
              sx={{ textTransform: 'capitalize', fontSize: '24px', fontWeight: '500' }}>
              Update {pageTitle === 'externaluser' ? 'DB Access' : pageTitle}
            </Typography>
            <IconButton
              aria-label="Close"
              onClick={handleClose}
              sx={{
                color: 'white'
              }}>
              <CloseIcon fontSize="20px" />
            </IconButton>
          </Stack>
          {children}
        </Box>
      </Fade>
    </Modal>
  )
}
export default UpdateModal
