import { useState } from 'react'
import { useLazyGetSerialNumbersQuery } from '../equipments/equipmentsApiSlice'
import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import BeatLoader from 'react-spinners/BeatLoader'
import ReportsList from './ReportsList'

const ReportSerialNumber = () => {
  const reportType = 'Serial Numbers'
  const [showTable, setShowTable] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [getSerialNumbers, { data, isLoading, isFetching, isError, error }] = useLazyGetSerialNumbersQuery({})

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (inputValue.trim() === '') {
      alert('Enter a list of serial numbers.')
    }

    if (inputValue.trim() !== '') {
      const numbersArray = inputValue.split('\n').map((serial) => serial.trim())
      const filteredNumbersArray = numbersArray.filter((serial) => serial !== '')
      await getSerialNumbers({ filteredNumbersArray, reportType })
      setInputValue('')
      setShowTable(true)
    }
  }

  const columns = [
    {
      field: 'distributorName',
      headerName: 'Distributor',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false,
      hideable: false
    },
    {
      field: 'registrantAward',
      headerName: 'Registrant Award',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantCardOrdered',
      headerName: 'Registrant Card Ordered',
      valueGetter: (params) => (params.row.registrantCardOrdered ? new Date(params.row.registrantCardOrdered).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantFirstName',
      headerName: 'Registrant First Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantLastName',
      headerName: 'Registrant Last Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantEmail',
      headerName: 'Registrant Email',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrantMoneyLoaded',
      headerName: 'Registrant Money Loaded',
      valueGetter: (params) => (params.row.registrantMoneyLoaded ? new Date(params.row.registrantMoneyLoaded).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'registrationDate',
      headerName: 'Registration Date',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      valueGetter: (params) => (params.row.registrationDate ? new Date(params.row.registrationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      sortable: false,
      resizable: false
    },
    {
      field: 'serialNumber',
      headerName: 'Serial #',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesFirstName',
      headerName: 'Sales First Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesLastName',
      headerName: 'Sales Last Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesEmail',
      headerName: 'Sales Email',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesAward',
      headerName: 'Sales Award',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesCardOrdered',
      headerName: 'Sales Card Ordered',
      valueGetter: (params) => (params.row.salesCardOrdered ? new Date(params.row.salesCardOrdered).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    },
    {
      field: 'salesMoneyLoaded',
      headerName: 'Sales Money Loaded',
      valueGetter: (params) => (params.row.salesMoneyLoaded ? new Date(params.row.salesMoneyLoaded).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      sortable: false,
      resizable: false
    }
  ]

  return (
    <>
      {!showTable && (
        <Grid>
          <p>
            Enter a list of serial numbers to create a table of those equipment. <small xs={{ color: 'lightgray' }}>One serial number per line.</small>
          </p>

          <Box>
            <form onSubmit={handleSubmit}>
              <TextField
                value={inputValue}
                onChange={handleInputChange}
                id="outlined-multiline-static"
                label=""
                multiline
                rows={10}
                sx={{ mb: 2 }}
                placeholder="Enter serial number(s) (one per line)..."
              />
              <Grid xs="auto">
                <Button
                  variant="contained"
                  sx={{ mr: 1 }}
                  type="submit"
                  disabled={inputValue ? false : true}>
                  Submit
                </Button>
              </Grid>
            </form>
          </Box>
        </Grid>
      )}

      {isLoading || isFetching ? (
        <BeatLoader
          color="#333"
          size={20}
          style={{ margin: '8px' }}
        />
      ) : isError ? (
        <Typography>{error?.message || 'An error occurred.'}</Typography>
      ) : (
        <>
          {showTable && (
            <>
              <ReportsList
                equipments={data}
                columns={columns}
                reportType={reportType}
              />
              <Button
                variant="contained"
                color="user"
                onClick={() => {
                  setShowTable(false)
                  setInputValue('')
                }}>
                Reset
              </Button>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ReportSerialNumber
