import { useState } from 'react'
import { useLazyGetFilteredEquipmentsQuery } from '../equipments/equipmentsApiSlice'
import { useGetDistributorsQuery } from '../distributors/distributorsApiSlice'
import CustomWindowedSelect from '../../components/CustomWindowedSelect'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import ReportsList from './ReportsList'
import BeatLoader from 'react-spinners/BeatLoader'
import CustomMultiDatePicker from '../../components/CustomMultiDatePicker'

const ReportDivvyServiceTech = () => {
  const reportType = 'Divvy-Service Tech'
  const [distributorName, setDistributorName] = useState('')
  const [distributorId, setDistributorId] = useState('')
  const [showTable, setShowTable] = useState(false)
  const { data: distributors } = useGetDistributorsQuery()
  const [getFilteredEquipment, { data: equipments, isLoading: isLoadingEquipments, isFetching: isFetchingEquipments, isError: isErrorEquipments, error: errorEquipments }] = useLazyGetFilteredEquipmentsQuery()
  const [shippingDates, setShippingDates] = useState([])
  const [shippingStartDate, shippingEndDate] = shippingDates
  const [resetKey, setResetKey] = useState(0)

  const columns = [
    {
      field: 'distributor.name',
      headerName: 'Distributor',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false,
      hideable: false,
      valueGetter: (params) => (params.row.distributor ? params.row.distributor.name : '')
    },
    {
      field: 'customer.name',
      headerName: 'Customer Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false,
      hideable: false,
      valueGetter: (params) => (params.row.customer ? params.row.customer.name : '')
    },
    {
      field: 'shippingDate',
      headerName: 'Shipping Date',
      valueGetter: (params) => (params.row.shippingDate ? new Date(params.row.shippingDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      resizable: false
    },
    {
      field: 'installationDate',
      headerName: 'Installation Date',
      valueGetter: (params) => (params.row.installationDate ? new Date(params.row.installationDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''),
      resizable: false
    },
    {
      field: 'preRegistrantFirstName',
      headerName: 'Pre-Registrant First Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'preRegistrantLastName',
      headerName: 'Pre-Registrant Last Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'preRegistrantEmail',
      headerName: 'Pre-Registrant Email',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'registrantFirstName',
      headerName: 'Registrant First Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'registrantLastName',
      headerName: 'Registrant Last Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'registrantEmail',
      headerName: 'Registrant Email',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'salesFirstName',
      headerName: 'Sales First Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'salesLastName',
      headerName: 'Sales Last Name',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'salesEmail',
      headerName: 'Sales Email',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'unitModel',
      headerName: 'Unit Model',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'compressorHorsepower',
      headerName: 'Horsepower',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'serialNumber',
      headerName: 'Serial #',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    },
    {
      field: 'purchaseOrder',
      headerName: 'Purchase Order #',
      maxWidth: 200,
      minWidth: 100,
      width: 125,
      resizable: false
    }
  ]

  const handleDistributorNameChange = (e) => {
    if (e === null) {
      setDistributorName('')
      setDistributorId('')
      return
    }
    setDistributorName(e.value.name)
    setDistributorId(e.value._id)
  }

  const handleFilterSubmit = async (e) => {
    e.preventDefault()
    const formatDate = (dateString) => {
      const date = new Date(dateString)
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const year = date.getFullYear()
      return `${month}/${day}/${year}`
    }

    const formattedShippingStartDate = shippingDates.length ? formatDate(shippingStartDate) : ''
    const formattedShippingEndDate = shippingDates.length ? formatDate(shippingEndDate) : ''

    const filteredEquipment = {
      distributorId,
      distributorName,
      shippingStartDate: formattedShippingStartDate,
      shippingEndDate: formattedShippingEndDate,
      reportType
    }
    await getFilteredEquipment(filteredEquipment)
    setShowTable(true)
  }

  const handleReset = (e) => {
    e.preventDefault()
    setResetKey((prevKey) => prevKey + 1)
    setDistributorName('')
    setDistributorId('')
    setShowTable(false)
    setShippingDates([])
  }

  const distributorOptions =
    distributors &&
    [...distributors]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((distributor) => {
        return { value: distributor, label: distributor.name, customLabel: `${distributor.address} ${distributor.city}, ${distributor.state} ${distributor.zip}` }
      })

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        marginBottom={1}
        alignItems="flex-end">
        <Grid
          xs={12}
          md={4}>
          <CustomWindowedSelect
            key={resetKey}
            options={distributorOptions}
            onChange={handleDistributorNameChange}
            titleLabel={
              <>
                Distributors - <small>leave blank for all distributors</small>
              </>
            }
          />
        </Grid>
        <Grid
          xs={12}
          md={4}
          xl={3}>
          <CustomMultiDatePicker
            label="Shipping Date"
            startDate={shippingStartDate}
            endDate={shippingEndDate}
            onChange={(update) => setShippingDates(update)}
          />
        </Grid>
        <Grid
          xs={12}
          md={3}>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            onClick={handleFilterSubmit}>
            Submit
          </Button>
          <Button
            variant="contained"
            color="user"
            onClick={handleReset}
            sx={{ mr: 1 }}>
            Reset
          </Button>
        </Grid>
      </Grid>

      {(isLoadingEquipments || isFetchingEquipments) && (
        <BeatLoader
          color={'#333'}
          size={20}
          style={{ margin: '8px' }}
        />
      )}

      {!(isLoadingEquipments || isFetchingEquipments) && (
        <>
          {isErrorEquipments && <Typography sx={{ py: 2 }}>{errorEquipments.data.message}</Typography>}

          {equipments && equipments.message && <Typography sx={{ py: 2 }}>{equipments.message}</Typography>}

          {equipments && !equipments.message && equipments.length === 0 && <Typography sx={{ py: 2 }}>No results</Typography>}

          {equipments && !equipments.message && equipments.length > 0 && showTable && (
            <ReportsList
              equipments={equipments}
              columns={columns}
              reportType={reportType}
            />
          )}
        </>
      )}
    </>
  )
}

export default ReportDivvyServiceTech
