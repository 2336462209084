import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import { useGetDistributorsQuery } from '../distributors/distributorsApiSlice'
import { useAddNewUnitMutation } from '../units/unitsApiSlice'
import { useModalContext } from '../../hooks/useModalContext'
import { toast } from 'react-toastify'
import CustomTextInput from '../../components/CustomTextInput'
import CustomWindowedSelect from '../../components/CustomWindowedSelect'
import { useTheme } from '@mui/material'

const CreateUnitForm = () => {
  const theme = useTheme()
  const [addNewUnit] = useAddNewUnitMutation()
  const { data: distributors } = useGetDistributorsQuery({}, 'distributorsList', {})
  const { handleCloseModal } = useModalContext()
  const [pending, setIsPending] = useState(false)
  const [model, setModel] = useState('')
  const [awardValue, setAwardValue] = useState('')
  const [distributor, setDistributor] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsPending(true)
    await addNewUnit({
      model,
      awardValue,
      distributor: `${distributor._id}`,
      isActive: true
    })
    handleCloseModal('createModal')
    toast.success(
      <>
        Unit <strong>{model}</strong> has been created.
      </>
    )
  }

  const handleDistributorChange = (e) => {
    if (e === null) {
      setDistributor(null)
    } else {
      setDistributor(e.value)
    }
  }

  const distributorOptions =
    distributors &&
    distributors
      .filter((distributor) => distributor.isLicensee === true && distributor.isActive === true)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((distributor) => {
        return { value: distributor, label: distributor.name, customLabel: `${distributor.address} ${distributor.city}, ${distributor.state} ${distributor.zip}` }
      })

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ px: 2, pb: 1, pt: 2 }}>
        <Grid
          container
          columnSpacing={2}>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={model}
              onChange={setModel}
              label="Model"
              name="model"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={awardValue}
              onChange={setAwardValue}
              label="Award Value"
              name="awardValue"
            />
          </Grid>
          <Grid
            sm={12}
            sx={{ mb: 1 }}>
            <CustomWindowedSelect
              isInModal={true}
              options={distributorOptions}
              onChange={handleDistributorChange}
              titleLabel="Licensee"
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{ px: 2, pb: 2 }}>
        <Stack
          direction="row"
          spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="cancel"
            onClick={() => handleCloseModal('createModal')}
            type="button">
            Cancel
          </Button>
          {!pending && (
            <Button
              variant="contained"
              size="small"
              type="submit"
              disabled={!(distributor && model)}
              sx={{
                backgroundColor: theme.vars.palette.slate.dark,
                '&:hover:not([disabled])': {
                  backgroundColor: theme.vars.palette.slate.darker
                },
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all'
                }
              }}>
              Add Unit
            </Button>
          )}
          {pending && (
            <Button
              variant="contained"
              size="small"
              type="button"
              disabled>
              <CircularProgress
                sx={{ color: theme.vars.palette.cancel.light, mr: 0.5 }}
                size={18}
              />
              Adding...
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
export default CreateUnitForm
