import { useCallback, useState } from 'react'
import DistributorsHeader from './DistributorsHeader'
import DistributorsList from './DistributorsList'

const DistributorsPage = () => {
  const [page, setPage] = useState(1)

  const handleSetPage = useCallback(
    (newPage) => {
      setPage(newPage)
    },
    [setPage]
  )

  return (
    <>
      <DistributorsHeader handleSetPage={handleSetPage} />
      <DistributorsList
        page={page}
        handleSetPage={handleSetPage}
      />
    </>
  )
}

export default DistributorsPage
