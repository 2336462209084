import { DataGrid } from '@mui/x-data-grid'
import CustomToolbar from './CustomToolbar'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'

const ReportsList = ({ equipments: rows, columns, reportType, hideColumns, columnGroupingModel }) => {
  const theme = useTheme()
  return (
    <Box
      style={{
        height: reportType !== 'External User' ? 550 : null,
        width: '100%'
      }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={rows}
        columns={columns}
        columnHeaderHeight={35}
        pageSize={50}
        pageSizeOptions={[50]}
        autoPageSize={reportType !== 'External User'}
        rowHeight={30}
        disablePageSize={true}
        disableColumnMenu={true}
        disableColumnResize={true}
        disableRowSelectionOnClick={true}
        slots={{
          toolbar: () => <CustomToolbar reportType={reportType} />
        }}
        sx={{
          '.totals': {
            backgroundColor: 'lightgray',
            color: '#222',
            fontWeight: '600'
          },
          '& .totals.no-style': {
            backgroundColor: 'rgb(108, 117, 125)',
            color: '#fff'
          },
          '.MuiDataGrid-columnHeaders': {
            backgroundColor: '#222',
            color: '#fff',
            fontSize: 14,
            [theme.getColorSchemeSelector('dark')]: {
              backgroundColor: '#999',
              color: '#222'
            }
          },
          '.MuiDataGrid-columnHeaders:focus': {
            outline: 'none'
          },
          '.MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
            display: 'none'
          },
          '&.MuiDataGrid-root': {
            border: 'none'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '.MuiDataGrid-footerContainer': {
            display: reportType === 'Totals' || reportType === 'Totals Expanded' ? 'none' : ''
          },
          ...(reportType === 'Totals' || reportType === 'Totals Expanded'
            ? {
                '.MuiDataGrid-row--lastVisible, .MuiDataGrid-row--lastVisible .totals, .MuiDataGrid-row--lastVisible:hover, .MuiDataGrid-row--lastVisible:focus': {
                  backgroundColor: 'rgb(108, 117, 125)',
                  color: '#fff',
                  pointerEvents: 'none'
                }
              }
            : {})
        }}
        initialState={{
          columns: {
            columnVisibilityModel: hideColumns
          },
          pagination: {
            paginationModel: { pageSize: 50 }
          }
        }}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel ?? null}
      />
    </Box>
  )
}

export default ReportsList
