import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useModalContext } from '../../hooks/useModalContext'
import { useUpdateUserMutation } from '../users/usersApiSlice'
import { useUpdateExtUserMutation } from '../users/extUsersApiSlice'
import { toast } from 'react-toastify'
import CustomTextInput from '../../components/CustomTextInput'
import { useTheme } from '@mui/material'

const PasswordModal = () => {
  const theme = useTheme()
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const { handleCloseModal, selectedRow: user, setSelectedRow, pageTitle, openModals } = useModalContext()

  const [updateUser] = useUpdateUserMutation()
  const [updateExtUser] = useUpdateExtUserMutation()

  const handleCancelClick = () => {
    setSelectedRow({})
    handleCloseModal('passwordModal')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (password !== password2) {
      toast.error(<>Passwords do not match.</>)
    } else {
      try {
        if (pageTitle === 'externaluser') {
          await updateExtUser({ id: user._id, password }).unwrap()
          toast.info(
            <>
              External user <strong>{user.name}</strong> password has been reset.
            </>
          )
        } else {
          await updateUser({ id: user._id, password }).unwrap()
          toast.info(
            <>
              User <strong>{user.name}</strong> password has been reset.
            </>
          )
        }
      } catch (error) {
        console.log(error)
        toast.error(<>Password was not reset.</>)
      }
    }
    handleCloseModal('passwordModal')
  }

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    handleCloseModal('passwordModal')
  }

  return (
    <Modal
      open={openModals.passwordModal}
      onClose={handleClose}
      closeAfterTransition>
      <Fade in={openModals.passwordModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: 300, width: '100%', boxShadow: 24, backgroundColor: theme.vars.palette.customBackground[theme.palette.mode] }}>
          <form onSubmit={handleSubmit}>
            <Stack
              direction="row"
              sx={{ justifyContent: 'space-between', alignItems: 'center', backgroundColor: theme.vars.palette.info.main, py: 1, pl: 2, pr: 1 }}>
              <Typography
                component="h4"
                sx={{ fontSize: 18, fontWeight: 500, lineHeight: 1, color: 'white' }}>
                Reset password
              </Typography>
              <IconButton
                aria-label="Close"
                onClick={handleClose}
                sx={{
                  color: 'white'
                }}>
                <CloseIcon fontSize="20px" />
              </IconButton>
            </Stack>
            <Box sx={{ p: 2 }}>
              <Typography sx={{ pb: 2 }}>
                Are you sure you want to reset <strong>{user.name}'s</strong> password?
              </Typography>
              <Box sx={{ pb: 2 }}>
                <CustomTextInput
                  isInModal={true}
                  value={password}
                  onChange={setPassword}
                  label="Password"
                  name="password"
                  type="password"
                />
              </Box>
              <Box sx={{ pb: 2 }}>
                <CustomTextInput
                  isInModal={true}
                  value={password2}
                  onChange={setPassword2}
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                />
              </Box>
              <Stack
                direction="row"
                spacing={1}
                sx={{ justifyContent: 'center' }}>
                <Button
                  size="small"
                  variant="contained"
                  color="cancel"
                  onClick={handleCancelClick}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  type="submit"
                  onClick={handleSubmit}>
                  Reset Password
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Fade>
    </Modal>
  )
}

export default PasswordModal
