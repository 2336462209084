import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const logsAdapter = createEntityAdapter({})

const initialState = logsAdapter.getInitialState()

export const logsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLog: builder.query({
      query: ({ id }) => `/logs/${id}`,
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError
      },
      providesTags: (result, error, id) => [{ type: 'Log', id }]
    }),
    getLogs: builder.query({
      query: () => '/logs',
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError
      },
      providesTags: (result, error, id) => [{ type: 'Log', id: 'LIST' }]
    }),
    getPaginatedLogs: builder.query({
      query: (params) => {
        const { page = 1, limit = 20, sorting = [], searchTerm = '' } = params
        return {
          url: '/logs',
          params: { page, limit, columnSort: sorting.length > 0 ? sorting[0].id : '', columnType: sorting.length > 0 ? sorting[0].desc : '', searchTerm },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      },
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Log', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Log', id }))]
        } else return [{ type: 'Log', id: 'LIST' }]
      }
    }),
    createLog: builder.mutation({
      query: (initialLog) => ({
        url: '/logs',
        method: 'POST',
        body: initialLog
      }),
      invalidatesTags: ['Log']
    }),
    updateLog: builder.mutation({
      query: (initialLog) => ({
        url: `/logs/${initialLog.id}`,
        method: 'PATCH',
        body: initialLog
      }),
      invalidatesTags: ['Log']
    }),
    deleteLog: builder.mutation({
      query: ({ id }) => ({
        url: `/logs/${id}`,
        method: 'DELETE',
        body: { id }
      }),
      invalidatesTags: ['Log']
    })
  })
})

export const { useGetLogQuery, useGetLogsQuery, useGetPaginatedLogsQuery, useCreateLogMutation, useUpdateLogMutation, useDeleteLogMutation } = logsApiSlice

export const selectLogsResult = logsApiSlice.endpoints.getLogs.select()

const selectLogsData = createSelector(selectLogsResult, (logsResult) => logsResult.data)

export const {
  selectAll: selectAllLogs,
  selectById: selectLogById,
  selectIds: selectLogIds
  // Pass in a selector that returns the posts slice of state
} = logsAdapter.getSelectors((state) => selectLogsData(state) ?? initialState)
