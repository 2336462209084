import { memo, useCallback, useMemo, useState } from 'react'
import { useGetPaginatedEquipmentsQuery } from './equipmentsApiSlice'
import useAuth from '../../hooks/useAuth'
import useTitle from '../../hooks/useTitle'
import BeatLoader from 'react-spinners/BeatLoader'
import CustomTableFooter from '../../components/CustomTableFooter'
import dayjs from 'dayjs'
import usePageTitle from '../../hooks/usePageTitle'
import { useModalContext } from '../../hooks/useModalContext'
import { useSearchContext } from '../../hooks/useSearchContext'
import { BsTrash } from 'react-icons/bs'
import { BsPencil } from 'react-icons/bs'
import { BsInfoCircle } from 'react-icons/bs'
import { useTheme } from '@mui/material'
import CustomTable from '../../components/CustomTable'
import CustomActionButtonGroup from '../../components/CustomActionButtonGroup'

const RecycledList = memo(({ page, handleSetPage }) => {
  const theme = useTheme()
  useTitle('Recycled | BILL Dashboard')
  usePageTitle('recycled')

  const { searchTerm } = useSearchContext()
  const { handleOpenModal, setSelectedRow } = useModalContext()

  const [sorting, setSorting] = useState([{ id: 'shippingDate', desc: true }])
  const [limit, setLimit] = useState(20)
  const { role } = useAuth()

  const handleInfoClick = useCallback(
    (selected) => {
      handleOpenModal('infoModal')
      setSelectedRow(selected)
    },
    [handleOpenModal, setSelectedRow]
  )

  const handleEditClick = useCallback(
    (selected) => {
      handleOpenModal('equipmentModal')
      setSelectedRow(selected)
    },
    [handleOpenModal, setSelectedRow]
  )

  const handleDeleteClick = useCallback(
    (selected) => {
      handleOpenModal('deleteModal')
      setSelectedRow(selected)
    },
    [handleOpenModal, setSelectedRow]
  )

  const handleLimitChange = useCallback(
    (e) => {
      handleSetPage(1)
      setLimit(+e.target.value)
    },
    [handleSetPage, setLimit]
  )

  const handleSetSorting = useCallback(
    (newSorting) => {
      handleSetPage(1)
      setSorting(newSorting)
    },
    [handleSetPage, setSorting]
  )

  const {
    data: equipments,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetPaginatedEquipmentsQuery({ page, limit, sorting, searchTerm, isRecycled: true }, 'equipmentsList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  const getColumnSx = (color) => ({
    fontSize: '.825rem',
    backgroundColor: `var(--mui-palette-${color})`,
    color: '#fff',
    border: 0,
    fontWeight: 'normal'
  })

  const columns = useMemo(
    () => [
      {
        header: 'Distributor',
        id: 'distributor',
        muiTableHeadCellProps: { sx: getColumnSx('maroon-dark') },
        columns: [
          {
            id: 'shippingDate',
            type: 'date',
            dateSetting: { locale: 'en-us' },
            header: 'Shipping Date',
            accessorKey: 'shippingDate',
            muiTableHeadCellProps: { sx: getColumnSx('maroon-main') },
            Cell: ({ row }) => (row?.original?.shippingDate ? dayjs(row?.original?.shippingDate).format('MM/DD/YYYY') : '')
          },
          {
            header: 'Name',
            id: 'distributorName',
            accessorKey: 'distributorName',
            muiTableHeadCellProps: { sx: getColumnSx('maroon-main') },
            size: 150
          }
        ]
      },
      {
        header: 'Registration',
        id: 'registation',
        muiTableHeadCellProps: { sx: getColumnSx('seafoam-dark') },
        columns: [
          {
            header: 'First',
            id: 'registrantFirstName',
            accessorKey: 'registrantFirstName',
            muiTableHeadCellProps: { sx: getColumnSx('seafoam-main') },
            flex: 1
          },
          {
            header: 'Last',
            id: 'registrantLastName',
            accessorKey: 'registrantLastName',
            muiTableHeadCellProps: { sx: getColumnSx('seafoam-main') },
            flex: 1
          },
          {
            header: 'Email',
            id: 'registrantEmail',
            accessorKey: 'registrantEmail',
            muiTableHeadCellProps: { sx: getColumnSx('seafoam-main') },
            flex: 1
          }
        ]
      },
      {
        header: 'Equipment',
        id: 'administration',
        muiTableHeadCellProps: { sx: getColumnSx('slate-dark') },
        columns: [
          {
            header: 'New Serial #',
            id: 'serialNumber',
            accessorKey: 'serialNumber',
            muiTableHeadCellProps: { sx: getColumnSx('slate-main') },
            flex: 1
          },
          {
            header: 'Old Serial #',
            id: 'serialNumberOld',
            accessorKey: 'serialNumberOld',
            muiTableHeadCellProps: { sx: getColumnSx('slate-main') },
            flex: 1
          },
          {
            header: 'Model',
            id: 'unitModel',
            accessorKey: 'unitModel',
            muiTableHeadCellProps: { sx: getColumnSx('slate-main') },
            flex: 1
          }
        ]
      },
      {
        header: 'Administration',
        id: 'administration',
        muiTableHeadCellProps: { sx: getColumnSx('eggplant-dark') },
        columns: [
          {
            header: 'RMA Number',
            id: 'rmaNumber',
            accessorKey: 'rmaNumber',
            muiTableHeadCellProps: { sx: getColumnSx('eggplant-main') }
          },
          {
            header: 'RMA Issue Date',
            id: 'rmaIssueDate',
            accessorKey: 'rmaIssueDate',
            muiTableHeadCellProps: { sx: getColumnSx('eggplant-main') },
            Cell: ({ row }) => (row?.original?.rmaIssueDate ? dayjs(row?.original?.rmaIssueDate).format('MM/DD/YYYY') : '')
          },
          {
            header: 'RMA Returned Date',
            id: 'rmaReturnedDate',
            accessorKey: 'rmaReturnedDate',
            muiTableHeadCellProps: { sx: getColumnSx('eggplant-main') },
            Cell: ({ row }) => (row?.original?.rmaReturnedDate ? dayjs(row?.original?.rmaReturnedDate).format('MM/DD/YYYY') : '')
          },
          {
            header: 'Actions',
            id: 'actions',
            size: 75,
            maxSize: 100,
            minSize: 75,
            muiTableHeadCellProps: () => ({
              align: 'right',
              sx: getColumnSx('eggplant-main')
            }),
            muiTableBodyCellProps: () => ({
              sx: { p: 0, m: 0 }
            }),
            Cell: ({ row }) => (
              <CustomActionButtonGroup
                row={row}
                buttons={[
                  {
                    title: 'Info',
                    color: 'info',
                    onClick: handleInfoClick,
                    icon: <BsInfoCircle />,
                    visible: true
                  },
                  {
                    title: 'Edit',
                    color: 'primary',
                    onClick: handleEditClick,
                    icon: <BsPencil />,
                    visible: role === 'admin' || role === 'superuser' ? true : false
                  },
                  {
                    title: 'Delete',
                    color: 'error',
                    onClick: handleDeleteClick,
                    icon: <BsTrash />,
                    visible: role === 'admin' || role === 'superuser' ? true : false
                  }
                ]}
              />
            )
          }
        ]
      }
    ],
    [handleDeleteClick, handleEditClick, handleInfoClick, role]
  )

  if (isLoading)
    return (
      <BeatLoader
        color={theme.vars.palette.slate.dark}
        size={20}
      />
    )

  if (isError) return <p className="errmsg">{error?.data?.message}</p>

  if (isSuccess) {
    const { docs } = equipments
    return (
      <>
        <CustomTable
          columns={columns}
          docs={docs}
          isError={isError}
          sorting={sorting}
          handleSetSorting={handleSetSorting}
          applyIsActiveStyle={false}
        />
        <CustomTableFooter
          page={page}
          handleSetPage={handleSetPage}
          data={equipments}
          limit={limit}
          handleLimitChange={handleLimitChange}
        />
      </>
    )
  }
})

export default RecycledList
