import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'

const CustomRadioButton = ({ value, label, checked, onChange, name }) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          sx={{
            py: 0.75,
            '& .MuiSvgIcon-root': { fontSize: 16 }
          }}
        />
      }
      label={
        <Typography
          component="span"
          sx={{ fontSize: 14 }}>
          {label}
        </Typography>
      }
      checked={checked}
      onChange={onChange}
      name={name}
    />
  )
}

export default CustomRadioButton
