import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useUserContext } from '../../hooks/useUserContext'
import { useUpdateExtUserMutation } from '../users/extUsersApiSlice'
import { useModalContext } from '../../hooks/useModalContext'
import { useGetDistributorsQuery } from '../distributors/distributorsApiSlice'
import { toast } from 'react-toastify'
import useAuth from '../../hooks/useAuth'
import CustomTextInput from '../../components/CustomTextInput'
import CustomWindowedSelect from '../../components/CustomWindowedSelect'
import { useTheme } from '@mui/material'

const UpdateExtUserForm = () => {
  const theme = useTheme()
  const { name: authUserName, role: authUserRole } = useAuth()
  const { setUserInfo } = useUserContext()
  const [updateUser] = useUpdateExtUserMutation()
  const { handleCloseModal, selectedRow: user } = useModalContext()
  const [selectedDistributors, setSelectedDistributors] = useState(
    user?.distributors.map((distributor) => {
      return { value: distributor, label: `${distributor.name} | ${distributor._id}`, customLabel: `${distributor.address} ${distributor.city}, ${distributor.state} ${distributor.zip}` }
    })
  )
  const { data: distributors, isLoading: isLoadingDistributors, isSuccess: isSuccessDistributors, isFetching: isFetchingDistributors } = useGetDistributorsQuery('distributorsList')
  const [name, setName] = useState(user?.name)
  const [email, setEmail] = useState(user?.email)
  const [isActive, setIsActive] = useState(user?.isActive)
  const [isPending, setIsPending] = useState(false)

  let distributorOptions

  if (!(isFetchingDistributors || isLoadingDistributors)) {
    distributorOptions = []
  }
  if (isSuccessDistributors) {
    const unselectedDistributors = distributors
      .filter((distributor) => distributor.isActive)
      .filter((option) => !selectedDistributors.some((selected) => selected.value._id === option._id))
      .sort((a, b) => a.name.localeCompare(b.name))

    distributorOptions = unselectedDistributors.map((distributor) => {
      return {
        value: distributor,
        label: `${distributor.name} | ${distributor._id}`,
        customLabel: `${distributor.address} ${distributor.city}, ${distributor.state} ${distributor.zip} ${distributor?.isLicensee ? ' - [LICENSEE]' : ''}`,
        key: distributor._id
      }
    })
  }

  const handleUpdate = async (e) => {
    e.preventDefault()
    setIsPending(true)

    const distributorNames = selectedDistributors.map((distributor) => {
      return distributor.value.name
    })
    const userData = {
      id: user._id,
      name,
      email,
      role: 'externaluser',
      isActive,
      distributors: selectedDistributors.map((distributor) => distributor.value),
      distributorNames
    }

    try {
      await updateUser(userData).unwrap()
      if (name === authUserName) {
        setUserInfo(userData)
      }
      toast.info(
        <>
          <Typography
            component="h5"
            fontWeight="bold">
            Updated!
          </Typography>
          <strong>{user.name}</strong> has been updated.
        </>
      )
    } catch (error) {
      toast.error(
        <>
          <Typography
            component="h5"
            fontWeight="bold">
            Error!
          </Typography>
          <strong>{user.name}</strong> has not been updated.
        </>
      )
    }
    handleCloseModal('updateModal')
  }

  const created = user.createdAt ? new Date(user.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) : 'N/A'
  const updated = user.updatedAt ? new Date(user.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) : 'N/A'

  const handleDistributorChange = (selectedOptions) => {
    setSelectedDistributors(selectedOptions)
  }

  return (
    <form>
      <Box sx={{ px: 2, py: 1 }}>
        <Grid
          container
          spacing={1}>
          <Grid
            sm={6}
            mb={1}>
            <CustomTextInput
              isInModal={true}
              value={name}
              onChange={setName}
              label="Name"
              name="name"
            />
          </Grid>
          <Grid
            sm={6}
            mb={1}>
            <CustomTextInput
              isInModal={true}
              value={email}
              onChange={setEmail}
              label="Email"
              name="email"
              type="email"
              disabled={authUserRole !== 'superuser'}
            />
          </Grid>
          <Grid
            xs
            mb={1}>
            <CustomWindowedSelect
              isInModal={true}
              options={distributorOptions}
              onChange={handleDistributorChange}
              titleLabel="Licensee"
              defaultValue={selectedDistributors}
              isMulti
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ px: 2, pb: 2 }}>
        <Typography sx={{ fontSize: '13px' }}>
          <strong>Date Created:</strong> {created}
          <br />
          <strong>Date Updated:</strong> {updated}
        </Typography>
        <FormControlLabel
          sx={{ fontSize: '14px' }}
          control={
            <Checkbox
              color="black"
              size="small"
              sx={{ py: 0 }}
            />
          }
          label="Active"
          labelPlacement="end"
          id="isLicenseeUpdate"
          onChange={(e) => setIsActive(e.target.checked)}
          checked={isActive}
        />
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start">
          <Button
            variant="contained"
            size="small"
            color="cancel"
            onClick={() => handleCloseModal('updateModal')}
            type="button">
            Cancel
          </Button>
          {!isPending && (
            <Button
              variant="contained"
              size="small"
              onClick={handleUpdate}
              color="black">
              Save Changes
            </Button>
          )}
          {isPending && (
            <Button
              variant="contained"
              size="small"
              type="button"
              disabled>
              <CircularProgress
                sx={{ color: theme.vars.palette.cancel.light, mr: 0.5 }}
                size={18}
              />
              Saving...
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
export default UpdateExtUserForm
