import { experimental_extendTheme as extendTheme } from '@mui/material/styles'

const commonColors = {
  primary: {
    light: '#4e9cea',
    main: '#1976d2',
    dark: '#11508e',
    contrastText: '#fff'
  },
  secondary: {
    main: '#0000ff'
  },
  info: {
    main: '#34a79a',
    contrastText: '#fff'
  },
  error: {
    main: '#d32f2f'
  },
  success: {
    main: '#357a38'
  },
  warning: {
    main: '#fbc02d'
  },
  black: {
    light: '#4b525a',
    main: '#282c30',
    dark: '#050606',
    contrastText: '#fff'
  },
  cancel: {
    light: '#939ba2',
    main: '#6c757d',
    dark: '#494f54',
    contrastText: '#fff'
  },
  seafoam: {
    light: '#51b0c8',
    main: '#31869b',
    dark: '#215967',
    darker: '#0e272d',
    contrastText: '#fff'
  },
  maroon: {
    light: '#c35553',
    main: '#963634',
    dark: '#632523',
    darker: '#2a100f',
    contrastText: '#fff'
  },
  eggplant: {
    light: '#856aa6',
    main: '#5f497a',
    dark: '#3f3151',
    darker: '#1a1421',
    contrastText: '#fff'
  },
  slate: {
    light: '#9bbae3',
    main: '#548dd4',
    dark: '#17365d',
    darker: '#081220',
    contrastText: '#fff'
  },
  unit: {
    light: '#9bbae3',
    main: '#5f91d3',
    dark: '#326ab4',
    contrastText: '#fff'
  },
  user: {
    light: '#596672',
    main: '#282c30',
    dark: '#212529',
    contrastText: '#fff'
  },
  externaluser: {
    light: '#596672',
    main: '#282c30',
    dark: '#212529',
    contrastText: '#fff'
  },
  customBorder: {
    light: 'rgba(0, 0, 0, 0.23)',
    dark: 'rgba(255, 255, 255, 0.23)'
  },
  inputBackground: {
    light: '#fbfbfb',
    dark: '#1e1e1e'
  },
  inputText: {
    light: '#222',
    dark: '#fff'
  },
  inputLabel: {
    light: '#222',
    dark: '#fff'
  },
  customBackground: {
    light: '#efefef',
    dark: '#373737',
    equipment: 'var(--mui-palette-slate-dark)',
    distributor: 'var(--mui-palette-maroon-dark)',
    customer: 'var(--mui-palette-seafoam-dark)',
    unit: 'var(--mui-palette-slate-dark)'
  }
}

const commonTypography = {
  fontFamily: ['system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'].join(','),
  h1: {
    fontSize: 45,
    fontWeight: 500
  },
  button: {
    textTransform: 'none'
  }
}

const commonComponents = {
  MuiTooltip: {
    defaultProps: {
      placement: 'top'
    }
  },
  MuiRadio: {
    defaultProps: {
      size: 'small',
      sx: {
        fontSize: '.875rem',
        width: 12,
        height: 12,
        mx: 1
      }
    }
  },
  MuiGrid2: {
    defaultProps: {
      disableEqualOverflow: true
    }
  },
  MuiTableCell: {
    styleOverrides: {
      fontSize: '.6rem',
      padding: '.5rem .4rem'
    }
  },
  MuiButton: {
    root: {
      borderRadius: 8
    }
  }
}

const commonShape = {
  borderRadius: 0
}

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        ...commonColors,
        customBackground: {
          ...commonColors.customBackground,
          user: 'var(--mui-palette-black-dark)',
          externaluser: 'var(--mui-palette-black-dark)'
        },
        background: {
          default: '#fff'
        }
      }
    },
    dark: {
      palette: {
        ...commonColors,
        customBackground: {
          ...commonColors.customBackground,
          user: 'var(--mui-palette-black-main)',
          externaluser: 'var(--mui-palette-black-main)'
        },
        background: {
          default: '#181818'
        }
      }
    }
  },
  typography: commonTypography,
  shape: commonShape,
  components: commonComponents
})

export { theme }
